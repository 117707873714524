import React, {useEffect, useState} from 'react'
import { Trans } from 'react-i18next';
import Chip from "../Chip";

export type GroupTypes = 'student' | "students" | "teachers" | "parents" | "staff";
export type GroupTypesRecord = Partial<Record<GroupTypes, boolean>>;
type GroupTypesValues = { type: GroupTypes, text: React.ReactNode }

const groupTypes: GroupTypesValues[] = [
  {
    type: "student",
    text: <Trans i18nKey="SidePanel.groups.student" />,
  },
  {
    type: "students",
    text: <Trans i18nKey="SidePanel.groups.students" />
  },
  {
    type: "parents",
    text: <Trans i18nKey="SidePanel.groups.parents" />
  },
  {
    type: "teachers",
    text: <Trans i18nKey="SidePanel.groups.teachers" />
  },
  {
    type: "staff",
    text: <Trans i18nKey="SidePanel.groups.staff" />
  }
];

interface GroupProps {
  showGroupTypes?: GroupTypesRecord;
  defaultValues?: GroupTypesRecord;
  title: string;
  subtitle?: string;
  onRemove?: () => void;
  onChange?: (selectedGroup: GroupTypesRecord) => void;
  children?: React.ReactNode;
}

const defaultGroupTypes: GroupTypesRecord = { students: false, parents: false, staff: false, teachers: false, student: false }
const Group = ({ showGroupTypes = defaultGroupTypes, title, subtitle, onRemove, onChange, defaultValues = {}, children }: GroupProps) => {
  // const dv = Object.keys(defaultValues).reduce((acc, key) => {
  //   if (showGroupTypes[key as GroupTypes]) {
  //     acc[key as GroupTypes] = defaultValues[key as GroupTypes]
  //   }
  //   return acc
  // }, {} as GroupTypesRecord)
  const [selectedGroup, setSelectedGroup] = useState<GroupTypesRecord>({
    students: false,
    teachers: false,
    parents: false,
    staff: false,
    ...defaultValues,
  })

  useEffect(() => {
    if (onChange) {
      onChange(selectedGroup);
    }
  }, [selectedGroup])

  const types = groupTypes.filter(({ type }) => showGroupTypes[type])

  return (
    <div className="added-assignation" role="None" style={{opacity: 1}}>
      <div className="panel-row assignation-row">
        <div className="assignation-title">
          <span className="title">{title}</span>
          <span className="subtitle">{subtitle}</span>
        </div>
        {
          types.length > 0 && (
            <div className="group-selection-assignation">
              {
                types.map(({type, text}, index) => {
                  return (
                    <Chip
                      key={index}
                      text={text as string}
                      selected={selectedGroup[type]}
                      onClick={
                        () => setSelectedGroup(
                          state => ({...state, [type]: !state[type]})
                        )
                      }
                    />
                  )
                })
              }
            </div>
          )
        }
        <i className="remove far fa-times delete-assignation" onClick={onRemove}></i>

        {children}
      </div>
    </div>
  )
}

export default Group;
