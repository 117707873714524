import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEdooImporter } from '../../data/hooks/useEdooImporter';

import Portal from '../Portal';
import ImporterDataType from './ImporterDataType';
import ImporterDataPreparations from './ImporterDataPreparations';
import ImporterFileUpload from './ImporterFileUpload';
import { EdooImporterSteps } from '../../settings';

import ImporterFileReview from './ImporterFileReview';
import styles from './importerForm.module.scss';

const ImporterForm: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { step } = useEdooImporter();

  return (
    <Portal selector={'#school-importer-container'}>
      <span className={styles.description}>{ t('EdooImporter.description') }</span>
      {/* Form Steps */}
      <div className={styles.stepsContainer}>
        <ImporterDataType
          isDisplaying={step !== undefined && step >= EdooImporterSteps.DataType}
          isActive={step === EdooImporterSteps.DataType}
        />
        <div className={styles.separator}/>
        <ImporterDataPreparations
          isDisplaying={step !== undefined && step >= EdooImporterSteps.Cycle}
          isActive={step === EdooImporterSteps.Cycle}
        />
        <div className={styles.separator}/>
        <ImporterFileUpload
          isDisplaying={step !== undefined && step >= EdooImporterSteps.UploadFile}
          isActive={step === EdooImporterSteps.UploadFile}
        />
        <div className={styles.separator}/>
        <ImporterFileReview
          isDisplaying={step !== undefined && step >= EdooImporterSteps.FileValidation}
          isActive={step === EdooImporterSteps.FileValidation}
        />
      </div>
    </Portal>
  );
};

export default ImporterForm;
