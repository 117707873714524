import { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ContextType } from '../../../data/types';
import { generateDocument } from '../../../adapters/documents';
import { GlobalEdooContext } from '../../../data/globalEdooContext';
import { shoutError } from '../../../utils';

type generateDocumentUrlProps = {
  documentId: string;
  childUuid: string;
};

type generateDocumentUrl = {
  isLoadingDocumentUrl: boolean;
  downloadUrl: string;
  generateDocumentUrl: () => Promise<void>;
  setDataDocumentUrl: ({ documentId, childUuid }: generateDocumentUrlProps) => Promise<void>;
};

export const useGenerateDocumentUrl = ({
  documentId,
  childUuid,
}: generateDocumentUrlProps): generateDocumentUrl => {
  const { t: translation } = useTranslation();
  const { session } = useContext<ContextType>(GlobalEdooContext);

  const [downloadUrl, setDownloadUrl] = useState('');
  const [isLoadingDocumentUrl, setIsLoadingDocumentUrl] = useState(false);
  const [dataState, setDataState] = useState({ documentId, childUuid });

  const generateDocumentUrl = async () => {
    if (!session) return;
    const jwtToken = session.token;

    setIsLoadingDocumentUrl(true);
    try {
      const response = await generateDocument(jwtToken, dataState.documentId, dataState.childUuid);
      if (response.message) throw new Error(response.message);
      setDownloadUrl(response.downloadUrl);
    } catch (responseError) {
      shoutError(translation('SuccessfulDataUpdate.errorGettingDocuments'));
    }
    setIsLoadingDocumentUrl(false);
  };

  const setDataDocumentUrl = async ({ documentId, childUuid }: generateDocumentUrlProps) => {
    setDataState({ ...dataState, documentId, childUuid });
  };

  useEffect(() => {
    if (dataState.documentId && dataState.childUuid) generateDocumentUrl();
  }, [dataState]);

  return {
    downloadUrl,
    isLoadingDocumentUrl,
    generateDocumentUrl,
    setDataDocumentUrl,
  };
};
