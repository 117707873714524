import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ContextType } from '../../data/types';
import { GlobalEdooContext } from '../../data/globalEdooContext';

import '../../i18n';

type translationPropsType = {
  children: React.ReactNode;
};

const Translation: React.FunctionComponent<translationPropsType> = ({
  children,
}: translationPropsType) => {
  const { session } = useContext<ContextType>(GlobalEdooContext);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (session?.user) i18n.changeLanguage(session.user.languageCode);
  }, [session]);

  return <>{children}</>;
};

export default Translation;
