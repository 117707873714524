import React from 'react';

import Portal from '../Portal';
import { NotificationForm } from '../NotificationForm';

export const NotificationPanel: React.FunctionComponent = () => {
  return (
    <>
      <Portal selector=".normal-panel #notification-panel">
        <NotificationForm />
      </Portal>

      <Portal selector=".tablet-panel #notification-panel">
        <NotificationForm />
      </Portal>
    </>
  );
};
