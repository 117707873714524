import { Files } from "../api";
import { GenerateUploadUrlPayload } from "./types";

interface GenerateUploadUrlProps {
  jwtToken: string;
  payload: GenerateUploadUrlPayload;
}

export const generateUploadUrl = async ({ payload, jwtToken }: GenerateUploadUrlProps) => {

  return await Files.custom.generateUploadUrl({
    data: payload,
    token: jwtToken,
  });
}
