import React, { CSSProperties } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCog,
  faCaretDown,
  IconDefinition,
  faCogs,
  faSignOutAlt,
  faBuilding,
  faExclamationCircle,
  faCheck,
  faCloudDownload,
  faFileExcel,
  faSitemap,
  faUser,
} from '@fortawesome/fontawesome-pro-regular';

import {
  faCircle,
  faCheckCircle,
} from '@fortawesome/fontawesome-pro-solid';

import styles from './icon.module.scss';

export type iconKeyType = 'cog' | 'cogs' | 'caretDown' | 'signOutAlt' | 'building'
                          | 'caretDown' | 'signOutAlt' | 'building' | 'circle'
                          | 'checkCircle' | 'exclamationCircle' | 'check'
                          | 'cloudDownload' | 'fileExcel' | 'sitemap' | 'user';

type tintType = 'black' | 'white' | 'label' | 'primary' | 'secondary' | 'disabled' | 'none';

type iconsType = {
  [key in iconKeyType]: IconDefinition;
};

type iconPropsType = {
  iconName: iconKeyType;
  iconSize?: SizeProp;
  style?: CSSProperties;
  tint?: tintType;
  className?: string;
};

const ICONS: iconsType = {
  cog: faCaretDown,
  caretDown: faCog,
  cogs: faCogs,
  signOutAlt: faSignOutAlt,
  building: faBuilding,
  checkCircle: faCheckCircle,
  circle: faCircle,
  exclamationCircle: faExclamationCircle,
  check: faCheck,
  cloudDownload: faCloudDownload,
  fileExcel: faFileExcel,
  sitemap: faSitemap,
  user: faUser,
};

const Icon: React.FunctionComponent<iconPropsType> = ({
  iconName,
  iconSize = '1x',
  tint = 'none',
  className,
  ...props
}: iconPropsType) => {
  if (!(iconName in ICONS)) return null;

  return <FontAwesomeIcon
    size={iconSize}
    icon={ICONS[iconName] as IconProp}
    className={
      `
        ${typeof styles[tint] !== 'undefined' ? styles[tint] : ''}
        ${typeof className !== 'undefined' ? className : ''}
      `
    }
    {...props} />;
};

export default Icon;
