import axios from "axios";

interface Props {
  file: File,
  url: string,
  onProgress?: (progress: number) => void,
  onUploadComplete?: () => void,
}


export const uploadFile = async ({ url, file, onProgress, onUploadComplete }: Props) => {
  // TODO: manage method, by default is PUT
  await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: (progressEvent) => {
      const progress = Math.round((progressEvent.loaded * 100) / (progressEvent.total || progressEvent.loaded))
      onProgress && onProgress(progress)
    },
  });

  onUploadComplete && onUploadComplete();
}
