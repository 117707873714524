import { AsyncFunction } from './types';
import { Search } from '../api';
import { OmniSearch, StructureSearch, TeacherSearch } from '../data/types';

export const searchStaff: AsyncFunction<string, TeacherSearch[]> = async (
  jwtToken: string,
  text: string,
) => {
  return await Search.custom.searchStaff({
    token: jwtToken,
    filters: { text, staff: '' },
  });
};

export const searchStructure: AsyncFunction<string, StructureSearch[]> = async (
  jwtToken: string,
  text: string,
) => {
  return await Search.custom.searchStructure({
    token: jwtToken,
    filters: { text },
  });
};

export const searchOmni: AsyncFunction<string, OmniSearch[]> = async (
  jwtToken: string,
  text: string,
) => {
  return await Search.custom.searchOmni({
    token: jwtToken,
    filters: { text },
  });
};
