import React from 'react';

interface ChipProps {
  selected?: boolean;
  text: string;
  onClick?: () => void;
}

const Chip = ({ selected, text, onClick }: ChipProps) => {
  return (
    <div className={`chip ${selected ? 'selected' : ''}`} onClick={onClick}>
      <div className="check-container">
        <i className="fa fa-check"></i>
      </div>
      <span className="text">{text}</span>
    </div>
  )
}

export default Chip;
