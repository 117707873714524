import { Schools } from '../api';
import { toSnakeCase, parseBoolToFormBooleans } from '../utils';

import {
  AsyncFunction,
  ConfigSuccessDataUpdateModalResponse,
  SaveSchoolConfigRequest,
  SaveSchoolConfigResponse,
  SchoolConfigResponse,
} from './types';

export const fetchSchoolConfiguration: AsyncFunction<string, SchoolConfigResponse> = async (
  jwtToken: string,
) => {
  return await Schools.custom.getSchoolConfiguration({ token: jwtToken });
};

export const saveSchoolConfiguration: AsyncFunction<
  SaveSchoolConfigRequest,
  SaveSchoolConfigResponse
> = async (request: SaveSchoolConfigRequest) => {
  const snakeCaseData = {
    ...toSnakeCase(request.config),
  };

  // Django receives this format for School Config: { 'show_personal_data_update': ['on'], etc... }
  // So, we need to parse all booleans to string array
  const data = parseBoolToFormBooleans(snakeCaseData);

  const { jwtToken } = request;
  return await Schools.custom.saveSchoolConfiguration({ token: jwtToken, data });
};

export const fetchConfigSuccessDataUpdateModal: AsyncFunction<
  string,
  ConfigSuccessDataUpdateModalResponse
> = async (jwtToken: string) => {
  return await Schools.custom.getConfigSuccessDataUpdateModal({ token: jwtToken });
};
