import React, { useEffect, useState, useContext } from 'react';
import { GlobalEdooContext } from '../../../data/globalEdooContext';
import { ContextType, SheetHeader } from '../../../data/types';
import {
  EdooImporterSteps,
  EdooImporterErrorTypes,
  PREFLIGHT_MODE,
  EDOO_IMPORTER_REQUIRED_FATHER_COLUMNS_TYPE,
  EDOO_IMPORTER_REQUIRED_MOTHER_COLUMNS_TYPE
} from '../../../settings';
import { Alert, Button, Loader } from '@nahualventure/paper-ui'
import ImporterStep, { FormStepProps } from '../ImporterStep';
import { Trans, useTranslation } from 'react-i18next';
import { useEdooImporter } from '../../../data/hooks/useEdooImporter';
import { uploadStudentsInBulk } from '../../../adapters/studentProfiles';
import ImporterSummaryTable from '../../ImporterSummaryTable';
import { StudentsBulkUploadRequest, StudentsBulkUploadResponseError } from '../../../adapters/types';
import Icon from '../../Icon';

import styles from './importerFile.module.scss';

type ImporterDonePlaceholderProps = {
  onResetStepper?: () => void,
};

const ImporterDonePlaceholder: React.FunctionComponent<ImporterDonePlaceholderProps> = ({
  onResetStepper
}: ImporterDonePlaceholderProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Icon
          iconSize={'2x'}
          tint={'primary'}
          iconName={'check'}
        />
        <span className={styles.title}>
          { t('EdooImporter.uploadSuccessful') }
        </span>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          onClick={onResetStepper}
        >
          { t("EdooImporter.resetImporterForm") }
        </Button>
      </div>
    </div>
  )
}

const ImporterFileReview: React.FunctionComponent<FormStepProps> = ({
  isActive = false,
  isDisplaying = false,
}:FormStepProps) => {
  const { session } = useContext<ContextType>(GlobalEdooContext);
  const [studentsCount, setStudentsCount] = useState<number>(0);
  const [tutorsCount, setTutorsCount] = useState<number>(0);
  const [isValidData, setIsValidData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);
  // Error Handling
  const [rowErrorsCount, setRowErrorsCount] = useState<number>(0);
  const [errorDetail, setErrorDetail] = useState<any[] | undefined>([]);
  const [errorType, setErrorType] = useState<string | undefined>(undefined);
  const [hasError, setHasError] = useState<boolean>(false)
  // Hooks
  const { t } = useTranslation();
  const { step, setStep, selectedCycle, fileData, setFileData,
    templateHeaders, setDataType, setSelectedCycle } = useEdooImporter();

  useEffect(() => {
    // Start preflight request
    setIsLoading(true);
    setRowErrorsCount(0);
    setErrorDetail([]);
    setErrorType(undefined);
    setHasError(false);

    const preflightUploadData = async () => {
      if (!session) return;
      const jwtToken = session!.token;
      const request: StudentsBulkUploadRequest = {
        cycle: selectedCycle?.id,
        mode: PREFLIGHT_MODE,
        students: fileData,
        jwtToken,
      }

      const response = await uploadStudentsInBulk(request).catch((err) => {
        const responseError:StudentsBulkUploadResponseError = err.data;
        if(responseError.error_type) {
          setErrorType(responseError.error_type);
          setRowErrorsCount(responseError.detail ? responseError.detail.length : 0);
          setErrorDetail(responseError.detail);
        }
        if(responseError.error) {
          setHasError(true);
        }
      });
      if(!!response) {
        // If everything is OK on preflight request, it means that data has been validated successfully!
        setIsValidData(!!response.message);
        countTutorsAndStudents();
      }
      setIsLoading(false)
    };

    if(isDisplaying) preflightUploadData()
  }, [selectedCycle, fileData, isDisplaying, step])

  const countTutorsAndStudents = () => {
    // As developer, I don't want to deal with hardcoded columns strings, so, I'll use templateHeaders
    // and get its value based on its index...
    if (templateHeaders && fileData) {
      // const studentHeaders = EDOO_IMPORTER_REQUIRED_STUDENTS_COLUMNS_IDXS.map(idx => templateHeaders[idx]);
      const fatherHeaders: SheetHeader[] = templateHeaders.filter(({ meta }) =>
        meta?.type === EDOO_IMPORTER_REQUIRED_FATHER_COLUMNS_TYPE && meta.isRequired
      );
      const motherHeaders: SheetHeader[] = templateHeaders.filter(({ meta }) =>
        meta?.type === EDOO_IMPORTER_REQUIRED_MOTHER_COLUMNS_TYPE && meta.isRequired
      );
      // Student info has been validated with preflight call, so, if has no errors, then:
      // setStudentsCount(fileData.length)

      // UPDATE Feb 3: Extra validations were done on backend two months after this code. So, we'll avoid them...

      // Extra validation: raise error if there is incomplete data from the mother or father
      // let invalidTutorRows = 0
      let validTutors = 0

      fileData?.forEach(data => {
        let hasValidFatherInfo = true;
        let hasValidMotherInfo = true;

        // Tutor: Validate father info
        const hasFatherInfo = fatherHeaders.some(({ label }) => label! in data && data[label!] !== undefined);
        if (hasFatherInfo) {
          // Check if all father rows have been filled
          hasValidFatherInfo = fatherHeaders.every(({ label }) => label! in data && data[label!] !== undefined)
        }

        // Tutor: Validate mother info
        const hasMotherInfo = motherHeaders.some(({ label }) => label! in data && data[label!] !== undefined);
        if (hasMotherInfo) {
          // Check if all father rows have been filled
          hasValidMotherInfo = motherHeaders.every(({ label }) => label! in data && data[label!] !== undefined)
        }

        /* if (!hasValidFatherInfo || !hasValidMotherInfo) {
          invalidTutorRows += 1;
          return;
        } */

        if(hasValidFatherInfo && hasFatherInfo) validTutors += 1;
        if(hasValidMotherInfo && hasMotherInfo) validTutors += 1;
      })

      setStudentsCount(fileData.length)
      setTutorsCount(validTutors);
      /* // After tutor validation, finally we update Alert message
      if (invalidTutorRows > 0) {
        setErrorType(EdooImporterErrorTypes.InvalidTutorRows);
        setRowErrorsCount(invalidTutorRows);
        setIsValidData(false);
      } else {
        setTutorsCount(validTutors);
      } */
    }
  }

  const getErrorMessage = () => {
    if (errorType === EdooImporterErrorTypes.InvalidHeaders) {
      return t('EdooImporter.validationInvalidHeadersError')
    } else if (errorType === EdooImporterErrorTypes.InvalidRows) {
        return (
          <Trans i18nKey="EdooImporter.validationInvalidRowsError">
          Cannot upload data. {{ rowErrorsCount }} rows were found with empty required fields.
        </Trans>
      )
    } /* else if (errorType === EdooImporterErrorTypes.InvalidTutorRows) {
      return t('EdooImporter.validationInvalidTutorError')
    }  */

    if (hasError) {
      // Generic Error
      return t('EdooImporter.validationError')
    }
    return undefined
  }

  const submitStudentsInBulk = () => {
    // Start final request
    setIsUploading(true);

    const finalUploadData = async () => {
      if (!session) return;
      const jwtToken = session!.token;
      const request: StudentsBulkUploadRequest = {
        cycle: selectedCycle?.id,
        students: fileData,
        jwtToken,
      }

      const response = await uploadStudentsInBulk(request).catch((err) => {
        const responseError:StudentsBulkUploadResponseError = err.data;
        if(responseError) {
          setHasError(true);
          setIsValidData(false);
        }
      });

      if(!!response) {
        // If everything is OK, data has been saved successfully!
        setIsDone(!!response.message);
        // As next step does not exists (FileValidation is last step), stepper-next button turns invisible
        setStep && setStep(EdooImporterSteps.FileValidation + 1);
      }
      setIsUploading(false)
    };

    finalUploadData()
  }

  const onNextStep = () => {
    if (isValidData) {
      submitStudentsInBulk();
      return
    }
    setIsDone(false);
    setErrorDetail(undefined);
    setIsValidData(false);
    setFileData && setFileData(undefined);
    setStep && setStep(EdooImporterSteps.FileValidation - 1);
  }

  const resetStepper = () => {
    // Reset all from useEdooImporter hook...
    setIsDone(false);
    setErrorDetail(undefined);
    setIsValidData(false);
    setFileData && setFileData(undefined);
    setDataType && setDataType("");
    setSelectedCycle && setSelectedCycle(undefined);
    setStep && setStep(EdooImporterSteps.DataType);
  }

  return (
    <ImporterStep
      isActive={isActive}
      isDisplaying={isDisplaying}
      title={t('EdooImporter.dataReview')}
      nextButtonTitle={isValidData ? t("EdooImporter.importData") : t("EdooImporter.reuploadCSV") }
      showNextButton={step === EdooImporterSteps.FileValidation}
      onNextStep={() => onNextStep()}
    >
      {
        (isLoading || isUploading) ? (
          <div className={styles.loader}>
            <Loader
              size="medium"
              tint="primary"
            />
          </div>
        ) : (
          <>
            {
              isDone ? (
                <ImporterDonePlaceholder
                  onResetStepper={() => resetStepper()}
                />
              ) : (
                <>
                  {
                    isValidData && (
                      <Alert className={styles.alertWarning} color="warning">
                        <Trans i18nKey="EdooImporter.validationWarning">
                          Check that the data you are about to create in Edoo is correct. You are about to create:
                          <strong>{studentsCount}</strong> students and
                          <strong>{tutorsCount}</strong> tutors.
                        </Trans>
                      </Alert>
                    )
                  }
                  {
                    !isValidData && (errorType || hasError) && (
                      <Alert color="error">
                        {getErrorMessage()}
                      </Alert>
                    )
                  }
                  <ImporterSummaryTable
                    headers={templateHeaders}
                    data={fileData}
                    details={errorDetail}
                  />

                </>
              )
            }
          </>
        )
      }

    </ImporterStep>
  )
}

export default ImporterFileReview;
