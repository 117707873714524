import { AsyncFunction } from './types';
import { Documents } from '../api';
import { DocumentDownloadUrl, SimpleDocument } from '../data/types';

export const getDocumentsPerStudent: AsyncFunction<string, SimpleDocument[]> = async (
  jwtToken: string,
  stageId: string,
) => {
  return await Documents.custom.getAllDocuments({
    urlPartParams: { stageId },
    token: jwtToken,
    filters: { stage_id: [stageId] },
  });
};

export const generateDocument: AsyncFunction<string, DocumentDownloadUrl> = async (
  jwtToken: string,
  documentId: string,
  childUuid: string,
) => {
  return await Documents.custom.generateDocumentUrl({
    urlPartParams: { documentId },
    token: jwtToken,
    data: { student_uuid: childUuid },
  });
};
