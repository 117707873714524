import React, { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Button, Switch } from '@nahualventure/paper-ui';
import { GlobalEdooContext } from '../../data/globalEdooContext';
import { ContextType } from '../../data/types';
import {
  fetchStageConfigWithMeta,
  saveStageConfiguration,
} from '../../adapters/stages';
import { RouteParamsType } from '../MultipageRoute';

import { SaveSchoolConfigRequest, SaveSchoolConfigResponse, SimpleSchoolConfig } from '../../adapters/types'
import { StageConfigTypes } from '../../settings';
import { shoutError, shoutSuccess } from '../../utils';

import styles from './stageConfig.module.scss';

type StageConfigProps = {
  // define your custom types here!
} & RouteParamsType;

export const StageMailingConfig: React.FunctionComponent<StageConfigProps> = ({ routeParams }: StageConfigProps) => {
  const { t } = useTranslation();
  const { session } = useContext<ContextType>(GlobalEdooContext);
  const [simpleStageConfig, setSimpleStageConfig] = useState<any | undefined>(undefined);
  const [fullStageConfig, setFullStageConfig] = useState<any | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!session) return;
      // Get token & stageId
      const jwtToken = session!.token;
      // TODO: Typescript error doing routeParams.stageId, fix later...
      const stageId = Object.values(routeParams).length ? Object.values(routeParams).pop() : 0;

      const response = await fetchStageConfigWithMeta(stageId, jwtToken).catch(() => setSimpleStageConfig(undefined));
      if(!!response) {
        // TODO: There is no easy way to transform this equivalent objects since we use Typescript in strict mode
        // So, we can't use object assigns with brackets, for example: simpleConfig[key] = fullConfig[key]...
        const fullConfig: any = response.config;
        const simpleConfig: any = {}
        Object.keys(fullConfig).forEach((key) => {
          simpleConfig[key] = fullConfig[key].value
        })
        setSimpleStageConfig(simpleConfig);
        setFullStageConfig(fullConfig);
      }
    };

    fetchData()
  }, [session])

  const saveStageConfigData = () => {
    const saveStageConfigData = async () => {
      if (!session) return;
      // Get token & stageId
      const jwtToken = session!.token;
      // TODO: Typescript error doing routeParams.stageId, fix later...
      const stageId = Object.values(routeParams).length ? Object.values(routeParams).pop() : 0;

      const request: SaveSchoolConfigRequest = {
        id: stageId,
        config: simpleStageConfig,
        jwtToken,
      }

      await saveStageConfiguration(request).then(() => {
        const successMessage = t('StageConfig.saveStageConfigSuccess');
        shoutSuccess(successMessage);
      }).catch((err) => {
        const responseError:SaveSchoolConfigResponse = err.data;
        if(responseError) {
          console.log(responseError.error);
          const errorMessage = t('StageConfig.saveStageConfigFail');
          shoutError(errorMessage);
        }
      });
    };

    saveStageConfigData()
  }

  const mailingKeys: string[] = fullStageConfig && Object.keys(fullStageConfig).filter((key) => {
    return fullStageConfig[key].metadata.type === StageConfigTypes.MAILING
  });

  return (
    <>
      <div>
        <div className={styles.innerTitle}>
          <h2> <i className="far fa-envelope" /> { t('StageConfig.mailingSection') } </h2>
        </div>
        <br />
        <hr className="clear-background" />

        {/* Stage Mailing Config Fields */}
        <h3> { t('StageConfig.mailingCustomSender')} </h3>
        <p>{ t('StageConfig.mailingCustomSenderDescription') }</p>

        {
          simpleStageConfig && mailingKeys && (
            <div className={styles.configContainer}>
              {
                mailingKeys.map((key: string) => (
                  <div className={styles.configItem} key={key}>
                    <div className={`row ${styles.centered}`}>
                      <label
                        className="label col xs-12 s-10"
                        onClick={() => {
                          const config: SimpleSchoolConfig = {...simpleStageConfig, [key]: !simpleStageConfig[key] };
                          setSimpleStageConfig(config);
                        }}
                        >
                        { t(`StageConfig.${key}`) }
                      </label>
                      <label className="col xs-12 s-2">
                        <div className={styles.switchContainer}>
                          <Switch
                            key={uuidv4()}
                            className={styles.status}
                            hasText
                            textPosition="left"
                            falseTitle={t('StageConfig.no')}
                            trueTitle={t('StageConfig.yes')}
                            size="medium"
                            value={simpleStageConfig[key]}
                            onChange={() => {
                              const config: SimpleSchoolConfig = {...simpleStageConfig, [key]: !simpleStageConfig[key] };
                              setSimpleStageConfig(config);
                            }}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
      <br />
      <br />

      <div>
        <Button
          fullWidth
          size="large"
          isLoading={isLoading}
          onClick={() => {
            setIsLoading(true);
            setTimeout(() => {


              saveStageConfigData()
              setIsLoading(false);
            }, 2000);
          }}
        >
          { t('StageConfig.saveConfig') }
        </Button>
      </div>
    </>
  );
}
// You can add another School Stage Config components here...
