const es = {
  UserMainActions: {
    configuration: 'Configuración',
    schoolConfiguration: 'Configuración del Colegio',
    logOut: 'Cerrar sesión',
  },
  SchoolConfig: {
    allowSkipUpdateData: 'Permitir saltar la actualización de datos',
    administrativeUsersToNotify: 'Usuarios a notificar cuando un tutor complete la actualización de datos',
    headerOnUpdateCompletion: 'Al completar la actualización',
    textToDisplayInDataUpdate: 'Texto a desplegar',
    allowToDownloadDocuments: 'Permitir descargar documentos',
    requestDataUpdate: 'Solicitar actualización de datos',
    requestDataUpdateDescription: 'Solicitar que los tutores actualicen sus datos en Edoo al acceder',
    requestDataUpdateButton: 'Solicitar Actualización',
    requestDataUpdateModalTitle: '¿Solicitar <1>actualización de datos</1> a los tutores?',
    requestDataUpdateModalContent: 'Esta acción mostrará una serie de formularios a los tutores, cuando accedan a Edoo, desde donde podrán <1>validar o actualizar su información personal y la de sus estudiantes.</1>',
    saveConfig: 'Guardar Configuración',
    sectionDataUpdate: 'Actualización de Datos',
    sectionFormUpdate: 'Formularios de Actualización',
    showTutorFormFields: 'Mostrar al Tutor los formularios de actualización de:',
    showLegalTutorFormFields: 'Mostrar al Encargado Legal los formularios de actualización de:',
    modalAccept: 'Continuar',
    modalDecline: 'Cancelar',
    yes: 'Sí',
    no: 'No',
    // General Fields
    // allowSkipTutorUpdateData: '',
    // Tutor Fields
    showPersonalDataUpdate: 'Datos Personales',
    showProfessionalDataUpdate: 'Datos Laborales',
    showFiscalDataUpdate: 'Datos Fiscales',
    showPickupMethodsDataUpdate: 'Métodos de Salida',
    showContactsDataUpdate: 'Tutores Legales',
    showMedicalDataUpdate: 'Datos Médicos',
    showLegalTutorsDataUpdate: 'Contactos',
    // Legal Tutor Fields
    showPersonalDataUpdateLegalTutor: 'Datos Personales',
    showProfessionalDataUpdateLegalTutor: 'Datos Laborales',
    showFiscalDataUpdateLegalTutor: 'Datos Fiscales',
    showPickupMethodsDataUpdateLegalTutor: 'Métodos de Salida',
    showContactsDataUpdateLegalTutor: 'Tutores Legales',
    showMedicalDataUpdateLegalTutor: 'Datos Médicos',
    showLegalTutorsDataUpdateLegalTutor: 'Contactos',
    saveSchoolConfigSuccess: 'Configuración guardada exitosamente',
    saveSchoolConfigFail: 'Error al actualizar configuración',
  },
  StageConfig: {
    mailingSection: 'Comunicación',
    mailingCustomSender: 'Remitentes personalizados de correos salientes',
    mailingCustomSenderDescription: 'Utilizar como remitente la dirección de correo electrónico del usuario que envía el correo.',
    // form fields (equivalent camelCase)
    useUserEmailInDisciplinaryFaults: 'Faltas Disciplinarias',
    useUserEmailInEvents: 'Eventos',
    useUserEmailInNotifications: 'Avisos',
    useUserEmailToSendCommentsAsSender: 'Comentarios en tareas',
    useUserEmailInAssistance: 'Asistencias',
    yes: 'Sí',
    no: 'No',
    saveConfig: 'Guardar Configuración',
    saveStageConfigSuccess: 'Configuración de nivel guardada exitosamente',
    saveStageConfigFail: 'Error al actualizar configuración de nivel',
  },
  EdooImporter: {
    description: 'Importa tus datos utilizando un archivo CSV o XLSX',
    dataType: 'Selecciona el tipo de data que cargarás',
    dataPrepare: 'Prepara la data que subirás',
    uploadCSV: 'Sube tu archivo CSV',
    dataReview: 'Revisa los datos que guardarás en Edoo',
    uploadAction: 'Importar mi data',
    selectDataType: 'Tipo de dato',
    selectCycle: 'Ciclo',
    preparationsDescription: 'Para cargar estudiantes debes haber creado previamente los <strong>Grados</strong>, <strong>Cursos</strong>, <strong>Secciones</strong> y <strong>Ciclos</strong> necesarios.',
    assignCycle: 'Ciclo al cual asignarás estos estudiantes:',
    dropFileHere: 'Arrastra y suelta el archivo aquí o ',
    selectFile: 'Selecciona uno',
    selectAnotherFile: 'Selecciona otro archivo',
    templateDownload: 'Plantilla de muestra',
    nextStep: 'Continuar',
    validationWarning: 'Revisa que los datos que estás a punto de crear en Edoo esten correctos. Estás por crear: <1>{{ studentsCount }}</1> estudiante(s) y <3>{{ tutorsCount }}</3> tutor(es).',
    validationInvalidRowsError: 'No se puede realizar la carga de datos. Se encontraron {{ rowErrorsCount }} filas con campos requeridos vacios o campos opcionales inválidos.',
    validationInvalidHeadersError: 'Compruebe que los datos que está a punto de crear tengan los encabezados correctos.',
    // validationInvalidTutorError: 'Compruebe que los campos obligatorios de los tutores no se encuentren parcialmente llenos.',
    validationError: 'Ocurrió un error al validar los datos',
    importData: 'Importar Mi Data',
    reuploadCSV: 'Volver a cargar CSV',
    uploadSuccessful: 'Data importada exitosamente',
    resetImporterForm: 'Realizar nueva importación',
  },
  EdooImporterDataTypes: {
    students: 'Estudiantes',
    staffs: 'Staffs',
    attitudinals: 'Actitudinales',
    levels: 'Grados',
    courses: 'Cursos',
  },
  SuccessfulDataUpdate: {
    modalTitle: 'Descarga la <1>documentación oficial</1>',
    modalFooter: 'También puedes descargar los documentos <2>accediendo a tu perfil</2>.',
    print: 'Imprimir',
    withoutDocuments: 'Sin documentos para descargar',
    errorGettingOfficialDocuments: 'Ocurrió un error al obtener la documentación oficial',
    errorGettingDocuments: 'Ocurrió un error al obtener los documentos.',
    loading: 'Por favor espere, estamos generando su documento',
  },
  SidePanel: {
    notificationTitle: 'Titulo',
    notificationDescription: 'Descripción',
    notificationTitleGroup: 'Selecciona los grupos a enviar aviso',
    removeFile: 'Remover archivo',
    searchBy: 'Búsqueda por',
    searchByStructure: 'Estructura',
    searchByPerson: 'Persona',
    submit: 'Enviar aviso',
    groups: {
      student: 'Estudiante',
      students: 'Estudiantes',
      parents: 'Padres',
      staff: 'Staff',
      teachers: 'Maestros',
    },
    editStudentListFrom: 'Editar listado de estudiantes de',
    editStudentList: 'Editar listado de estudiantes',
    allStudents: 'Todos los estudiantes'
  },
};

export default es;
