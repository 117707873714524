import React from 'react';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import DropFileZonePlaceholder from '../DropFileZonePlaceholder';

import styles from './fileUploader.module.scss';


type FileUploaderTypes = {
  onFileUpload?: (data: Object[]) => void,
}

const FileUploader: React.FunctionComponent<FileUploaderTypes> = ({
  onFileUpload,
}: FileUploaderTypes) => {

  const createData = (parsedRows: Array<any>) => {
    // console.log(parsedRows);
    onFileUpload && onFileUpload(parsedRows);
  }

  const onDrop = (files: Array<Blob>) => {
    const excelFile:Blob = files[0];
    if (excelFile == null) return;
    const fileReader = new FileReader();
    const rABS = !!fileReader.readAsBinaryString;

    fileReader.onload = () => {
      const file = fileReader.result;
      const workbook = XLSX.read(file, { type: rABS ? 'binary' : 'array', cellDates:true});
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const excelRows: Array<any> = XLSX.utils.sheet_to_json(worksheet, { header: 1, dateNF:'yyyy-mm-dd'});
      const headers: Array<any> = excelRows.splice(0, 1)[0];

      const parsedRows = excelRows.reduce((result: any, row: Array<any>) => {
        // Lodash zipObject function avoided due to types issue...
        if(row.length) {
          let item = {};
          headers.forEach((val: any, index: number) => {
            // Parse dates as string
            const dateValue = moment(row[index] , 'YYYY-MM-DD', true)
            item = {
              ...item,
              [val]: dateValue.isValid() ? dateValue.format('YYYY-MM-DD') : row[index]
            }
          })
          result.push(item)
        }
        return result;
      }, []);

      return createData(parsedRows);
    };

    if (rABS) fileReader.readAsBinaryString(excelFile);
    else fileReader.readAsArrayBuffer(excelFile);
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    open,
  } = useDropzone({
    onDrop,
    accept: '.xlsx, .csv, .xls',
    noClick: true,
    maxFiles: 1,
  });

  return (
    <div
      {...getRootProps({ onClick: e => e.preventDefault() })}
      className={`${styles.dropzone} ${isDragActive ? styles.enter : ''}`}
    >
      <input {...getInputProps()} />
      <DropFileZonePlaceholder
        acceptedFiles={acceptedFiles}
        onSelectFile={open}
        isDragActive={isDragActive}
      />
    </div>
  );
}

export default FileUploader;
