import React from 'react';
import { lightTheme, PaperThemeProvider } from '@nahualventure/paper-ui';

import MultipageRouter from '../MultipageRouter';
import MultipageRoute from '../MultipageRoute';
import Translation from '../Translation';

import GlobalEdooContext from '../../data/globalEdooContext';

import ChangeLanguage from './ChangeLanguage';
import ConfigUpdateTutorInfo from './ConfigUpdateTutorInfo';
import Login from './Login';
import Omni from './Omni';
import SchoolImporter from './SchoolImporter';
import StageMailingConfig from './StageMailingConfig';
// import ForgotPassword from './ForgotPassword';
import { SuccessfulDataUpdate } from './SuccessfulDataUpdate';
import { NotificationPanel } from './NotificationPanel';
import { sidePanelUrls } from '../../utils/teacherSidePanelUrls';

type EdooProps = {
  path: string;
};

const Edoo: React.FunctionComponent<EdooProps> = ({ path }: EdooProps) => (
  <GlobalEdooContext>
    <PaperThemeProvider theme={lightTheme}>
      <Translation>
        <MultipageRouter path={path}>
          <MultipageRoute route="^/user/login" Component={Login} />
          {/* <MultipageRoute route="^/user/forgot-password" component={<ForgotPassword />} /> */}
          {/** this component is mounted in all views */}
          <MultipageRoute route="^/" Component={Omni} />

          {sidePanelUrls.map((url) => (
            <MultipageRoute key={url} route={`^${url}`} Component={NotificationPanel} />
          ))}

          <MultipageRoute route="^/dashboard/configuration/language/" Component={ChangeLanguage} />

          <MultipageRoute route="^/dashboard/tutor/" Component={SuccessfulDataUpdate} />
          <MultipageRoute route="^/profile/tutor/:username/" Component={SuccessfulDataUpdate} />

          <MultipageRoute
            route="^/school/configuration-update-data/"
            Component={ConfigUpdateTutorInfo}
          />
          <MultipageRoute route="^/school/importer/" Component={SchoolImporter} />

          <MultipageRoute
            route="^/stage-backend/:stageId/mailing-config/"
            Component={StageMailingConfig}
          />
        </MultipageRouter>
      </Translation>
    </PaperThemeProvider>
  </GlobalEdooContext>
);

export default Edoo;
