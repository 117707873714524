import React from 'react';
import { createRoot } from 'react-dom/client';
import Edoo from './components/views/Edoo';

const root = createRoot(document.getElementById('edoo2') as HTMLElement);

root.render(
  <React.StrictMode>
    <Edoo path={window.location.pathname} />
  </React.StrictMode>
);
