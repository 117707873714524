import { Sections } from "../api";
import { MinimalStudent } from "./types";

interface GetSectionStudentsProps {
  jwtToken: string;
  id: number | string;
}

export const getSectionStudents = async ({
  jwtToken,
  id,
}: GetSectionStudentsProps): Promise<MinimalStudent[]> => {

  return (await Sections.custom.getStudents({
    token: jwtToken,
    urlPartParams: { id },
  })).students;
}
