import React from 'react';

import UserMainActions from '../UserMainActions';

const Omni: React.FunctionComponent = () => (
  <>
    <UserMainActions />
  </>
);

export default Omni;
