export const getSize = ({ size }: { size: number }): string => {
  let dimension = 'KB';
  let val = size / 1000.0;

  if (val > 1000.0) {
    val /= 1000.0;
    dimension = 'MB';
  }

  if (val > 1000.0) {
    val /= 1000.0;
    dimension = 'GB';
  }

  return `${val.toFixed(2)}${dimension}`;
};

export const getExtension = ({ filename }: { filename: string }): string => {
  const extension = filename.split('.').pop();
  return extension || '';
};

export const getFileIcon = ({ extension }: { extension: string }): string => {
  const extensionMappings: Record<string, string> = {
    doc: 'file-word',
    docx: 'file-word',
    pdf: 'file-pdf',
    ppt: 'file-powerpoint',
    pptx: 'file-powerpoint',
    mp4: 'file-video',
    mkv: 'file-video',
    webm: 'file-video',
    avi: 'file-video',
    mov: 'file-video',
    xls: 'file-excel',
    xlsx: 'file-excel',
    html: 'file-code',
    zip: 'file-archive',
    rar: 'file-archive',
    txt: 'file-alt',
    csv: 'file-csv',
    jpg: 'file-image',
    jpeg: 'file-image',
    png: 'file-image',
    webp: 'file-image',
    link: 'link',
  };

  const lowercaseExtension = extension.toLowerCase();
  return extensionMappings[lowercaseExtension] || 'file';
};
