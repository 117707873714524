import React from 'react';

import { EdooImporterProvider } from '../../data/hooks/useEdooImporter';
import ImporterForm from '../ImporterForm';

const SchoolImporter: React.FunctionComponent = () => (
  <EdooImporterProvider>
    <ImporterForm />
  </EdooImporterProvider>
);

export default SchoolImporter;
