import React from 'react';

import { RouteParamsType } from '../MultipageRoute';
import { SuccessfulDataUpdateModal } from '../Modals';

type SuccessfulDataUpdateProps = {
  routeParams: RouteParamsType;
};

export const SuccessfulDataUpdate: React.FunctionComponent<SuccessfulDataUpdateProps> = ({
  routeParams,
}: SuccessfulDataUpdateProps) => {
  return <SuccessfulDataUpdateModal routeParams={routeParams} />;
};
