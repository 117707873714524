import React from 'react';

import { useTranslation } from 'react-i18next';

import { getExtension, getFileIcon, getSize } from '../../../utils/fileManager';
import { ProgressCircle } from '../../ProgressCircle';
import styles from './SelectedFile.module.scss';

interface SelectedFileProps {
  uuid: string;
  file: File;
  handleRemoveFile: ({ uuid }: { uuid: string }) => void;
  loading?: boolean;
  progress?: number;
  twinkle?: boolean;
}

export const SelectedFile: React.FunctionComponent<SelectedFileProps> = ({
  uuid,
  file: { name, size },
  handleRemoveFile,
  loading,
  progress = 0,
  twinkle = true,
}: SelectedFileProps) => {
  const { t: translation } = useTranslation();

  return (
    <span
      title={name}
      // TODO: recreate legacy styles in react, the current version is a mix of react and legacy styles
      className={`file ${loading ? `loading ${styles['loading']}` : ''} ${styles['file']}`}
      id={uuid}
      key={uuid}
    >
      <span className="progress-percentage">
        <ProgressCircle stroke={2} radius={13} progress={progress} twinkle={twinkle} />
      </span>
      <span className="icon">
        <i
          className={`fal fa-${getFileIcon({
            extension: getExtension({ filename: name }),
          })}`}
        ></i>
      </span>
      <span className="text">{name}</span>
      <span className="size">{getSize({ size })}</span>
      <span
        className="remove"
        title={translation('SidePanel.removeFile')}
        onClick={() => handleRemoveFile({ uuid })}
      >
        <i className="far fa-times"></i>
      </span>
    </span>
  );
};
