import React, { useState, useEffect, useContext } from 'react';

import { Button, Loader, Switch, Typeahead, OPTION_TYPE } from '@nahualventure/paper-ui';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { ContextType, TeacherSearch } from '../../data/types';
import { fetchSchoolConfiguration, saveSchoolConfiguration } from '../../adapters/schools';
import { GlobalEdooContext } from '../../data/globalEdooContext';
import { requireTutorsToUpdateData } from '../../adapters/tutorProfiles';
import {
  SaveSchoolConfigRequest,
  SaveSchoolConfigResponse,
  SimpleSchoolConfig,
  SimpleResponse,
} from '../../adapters/types';
import { SchoolConfigTypes } from '../../settings';
import { searchStaff } from '../../adapters/searches';
import { shoutError, shoutSuccess } from '../../utils';
import { UserSearchRenderItem, UserSelected } from '../UserSearchEngine';
import { RequestUpdateDataModal } from '../Modals';

import styles from './schoolConfig.module.scss';

export const ConfigUpdateInfo: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { session } = useContext<ContextType>(GlobalEdooContext);

  // NOTE: I'm really sorry Typescript, but at this point we don't need it cause in this component
  // we'll be copying props with brackets approach (simpleSchoolConfig[key] = fullSchoolConfig[key])...
  const [simpleSchoolConfig, setSimpleSchoolConfig] = useState<any | undefined>(undefined);
  const [fullSchoolConfig, setFullSchoolConfig] = useState<any | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!session) return;
      const jwtToken = session!.token;

      const response = await fetchSchoolConfiguration(jwtToken).catch(() =>
        setSimpleSchoolConfig(undefined),
      );
      if (!!response) {
        // TODO: There is no easy way to transform this equivalent objects since we use Typescript in strict mode
        // So, we can't use object assigns with brackets, for example: simpleConfig[key] = fullConfig[key]...
        const fullConfig: any = response.config;
        const simpleConfig: any = {};
        Object.keys(fullConfig).forEach((key) => {
          simpleConfig[key] = fullConfig[key].value;
        });
        setSimpleSchoolConfig(simpleConfig);
        setFullSchoolConfig(fullConfig);
      }
    };

    fetchData();
  }, [session]);

  const saveSchoolConfigData = () => {
    const saveSchoolConfigData = async () => {
      if (!session) return;
      const jwtToken = session!.token;
      const request: SaveSchoolConfigRequest = {
        config: simpleSchoolConfig,
        jwtToken,
      };

      await saveSchoolConfiguration(request)
        .then(() => {
          const successMessage = t('SchoolConfig.saveSchoolConfigSuccess');
          shoutSuccess(successMessage);
        })
        .catch((err) => {
          const responseError: SaveSchoolConfigResponse = err.data;
          if (responseError) {
            console.log(responseError.error);
            const errorMessage = t('SchoolConfig.saveSchoolConfigFail');
            shoutError(errorMessage);
          }
        });
    };

    saveSchoolConfigData();
  };

  const onRequireTutorsToUpdateData = () => {
    const onRequireTutorsToUpdateData = async () => {
      if (!session) return;
      const jwtToken = session!.token;

      const response = await requireTutorsToUpdateData(jwtToken).catch((err) => {
        const responseError: SimpleResponse = err.data;
        if (responseError) {
          console.log(responseError);
          const errorMessage = t('SchoolConfig.saveSchoolConfigFail');
          shoutError(errorMessage);
        }
      });

      if (!!response) {
        const message = response.message;
        // If everything is OK, data has been saved successfully! Just close modal...
        if (message) {
          const successMessage = t('SchoolConfig.saveSchoolConfigSuccess');
          shoutSuccess(successMessage);
          setIsModalOpen(false);
        } else {
          console.log(response);
          const errorMessage = t('SchoolConfig.saveSchoolConfigFail');
          shoutError(errorMessage);
        }
      }
    };
    onRequireTutorsToUpdateData();
  };

  const tutorKeys: string[] =
    fullSchoolConfig &&
    Object.keys(fullSchoolConfig).filter((key) => {
      return fullSchoolConfig[key].metadata.type === SchoolConfigTypes.TUTOR;
    });

  const legalTutorKeys: string[] =
    fullSchoolConfig &&
    Object.keys(fullSchoolConfig).filter((key) => {
      return fullSchoolConfig[key].metadata.type === SchoolConfigTypes.LEGAL_TUTOR;
    });

  const addAdministrativeUsersToNotify = (selectedItem: OPTION_TYPE | null) => {
    if (!selectedItem) return;
    const administrativeUsersToNotify = simpleSchoolConfig?.administrativeUsersToNotify || [];

    const userAlreadyInList = administrativeUsersToNotify.find((user: OPTION_TYPE) => {
      if (
        !(typeof selectedItem === 'string' || selectedItem instanceof String) &&
        !(typeof user === 'string' || user instanceof String)
      ) {
        return user.value === selectedItem.value;
      }
      return false;
    });
    if (userAlreadyInList) return;

    const config: SimpleSchoolConfig = {
      ...simpleSchoolConfig,
      administrativeUsersToNotify: [...administrativeUsersToNotify, selectedItem],
    };
    setSimpleSchoolConfig(config);
  };

  const removeAdministrativeUsersToNotify = (value: string | number) => {
    const administrativeUsersToNotify = simpleSchoolConfig?.administrativeUsersToNotify || [];

    const config: SimpleSchoolConfig = {
      ...simpleSchoolConfig,
      administrativeUsersToNotify: administrativeUsersToNotify.filter((user: OPTION_TYPE) => {
        if (!(typeof user === 'string' || user instanceof String)) return user.value !== value;
        return false;
      }),
    };
    setSimpleSchoolConfig(config);
  };

  const fetchItems = async (name: string): Promise<OPTION_TYPE[]> => {
    if (!session) return [];
    const jwtToken = session.token;
    try {
      const response = await searchStaff(jwtToken, name);

      return response.map(
        ({ userPk, firstName, lastName, username, profilePicture }: TeacherSearch) => ({
          value: userPk,
          label: `${firstName} ${lastName}`,
          subLabel: username,
          imageUrl: profilePicture,
        }),
      );
    } catch (responseError) {
      console.log(responseError);
      return [];
    }
  };

  return (
    <>
      <div className={styles.configSection}>
        <div className={styles.innerTitle}>
          <h2>
            {' '}
            <i className="far fa-cog" /> {t('SchoolConfig.sectionDataUpdate')}{' '}
          </h2>
        </div>
        <br />
        <div className={`row ${styles.centered}`}>
          <label
            className="label col xs-12 s-10"
            onClick={() => {
              const config: SimpleSchoolConfig = {
                ...simpleSchoolConfig,
                allowSkipTutorUpdateData: !simpleSchoolConfig?.allowSkipTutorUpdateData,
              };
              setSimpleSchoolConfig(config);
            }}
          >
            {t('SchoolConfig.allowSkipUpdateData')}
          </label>
          <label className="col xs-12 s-2">
            <div className={styles.switchContainer}>
              <Switch
                className={styles.status}
                hasText
                textPosition="left"
                falseTitle={t('SchoolConfig.no')}
                trueTitle={t('SchoolConfig.yes')}
                size="medium"
                value={simpleSchoolConfig?.allowSkipTutorUpdateData}
                onChange={() => {
                  const config: SimpleSchoolConfig = {
                    ...simpleSchoolConfig,
                    allowSkipTutorUpdateData: !simpleSchoolConfig?.allowSkipTutorUpdateData,
                  };
                  setSimpleSchoolConfig(config);
                }}
              />
            </div>
          </label>
        </div>
        <br />
        <div className={styles.requestUpdateContainer}>
          <label className="label">{t('SchoolConfig.requestDataUpdate')}</label>
          <span className={styles.labelDescription}>
            {t('SchoolConfig.requestDataUpdateDescription')}
          </span>
          <Button
            color="success"
            colorVariant="main"
            size="medium"
            variant="default"
            onClick={(e) => {
              e.preventDefault();
              setIsModalOpen(true);
            }}
          >
            {t('SchoolConfig.requestDataUpdateButton')}
          </Button>
        </div>

        <div className={`${styles.requestUpdateContainer} ${styles.searchContainer}`}>
          <label>{t('SchoolConfig.administrativeUsersToNotify')}</label>
          {!session && (
            <div
              style={{
                minWidth: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Loader size="x-small" text="" textPosition="bottom" tint="primary" />
            </div>
          )}

          {session && (
            <Typeahead
              clearIcon="times"
              fullWidth
              hover="primary"
              icon="search"
              inputDebounce={500}
              items={[]}
              placeholder="Busca un usuario..."
              size="small"
              tint="primary"
              onSelect={addAdministrativeUsersToNotify}
              fetchItems={fetchItems}
              renderItem={(option: OPTION_TYPE, idx: number, itemProps?) => (
                <UserSearchRenderItem option={option} idx={idx} itemProps={itemProps} />
              )}
              renderSelectedItem={(option: OPTION_TYPE, onReset) => {
                // return <UserSearchRenderSelectedItem option={option} onReset={onReset} />
                option; // Just for not showing error
                onReset();
                return <></>;
              }}
            />
          )}

          <div className="row">
            {(simpleSchoolConfig?.administrativeUsersToNotify || []).map(
              (user: OPTION_TYPE, index: number) => {
                const itemID =
                  typeof user === 'string' || user instanceof String ? index : user.value;
                return (
                  <div className="col xs-12 s-6 m-4" key={itemID}>
                    <UserSelected
                      itemID={itemID}
                      option={user}
                      onReset={removeAdministrativeUsersToNotify}
                    />
                  </div>
                );
              },
            )}
          </div>
        </div>

        <h3 style={{ marginTop: '1rem' }}> {t('SchoolConfig.headerOnUpdateCompletion')} </h3>

        <div className={styles.requestUpdateContainer} style={{ marginTop: '1rem' }}>
          <label>{t('SchoolConfig.textToDisplayInDataUpdate')}</label>
          <div className="input-wrapper area" style={{ marginBottom: 0 }}>
            <textarea
              id="textToDisplayInDataUpdate"
              name="textToDisplayInDataUpdate"
              rows={1}
              cols={10}
              value={simpleSchoolConfig?.textToDisplayInDataUpdate}
              onChange={({ target }) => {
                const config: SimpleSchoolConfig = {
                  ...simpleSchoolConfig,
                  textToDisplayInDataUpdate: target.value,
                };
                setSimpleSchoolConfig(config);
              }}
            />
          </div>
        </div>

        <div className={styles.requestUpdateContainer} style={{ marginTop: '1rem' }}>
          <div className={`row ${styles.centered}`}>
            <label
              className="label col xs-12 s-9"
              onClick={() => {
                const config: SimpleSchoolConfig = {
                  ...simpleSchoolConfig,
                  allowToDownloadDocuments: !simpleSchoolConfig['allowToDownloadDocuments'],
                };
                setSimpleSchoolConfig(config);
              }}
            >
              {t('SchoolConfig.allowToDownloadDocuments')}
            </label>
            <label className="col xs-12 s-3">
              <div className={styles.switchContainer}>
                {fullSchoolConfig && (
                  <Switch
                    key={uuidv4()}
                    className={styles.status}
                    hasText
                    textPosition="left"
                    falseTitle={t('SchoolConfig.no')}
                    trueTitle={t('SchoolConfig.yes')}
                    size="medium"
                    value={simpleSchoolConfig['allowToDownloadDocuments']}
                    onChange={() => {
                      const config: SimpleSchoolConfig = {
                        ...simpleSchoolConfig,
                        allowToDownloadDocuments: !simpleSchoolConfig['allowToDownloadDocuments'],
                      };
                      setSimpleSchoolConfig(config);
                    }}
                  />
                )}
              </div>
            </label>
          </div>
        </div>
      </div>

      <div className={styles.configSection}>
        <div className={styles.innerTitle}>
          <h2>
            <i className="far fa-clipboard-list-check" /> {t('SchoolConfig.sectionFormUpdate')}{' '}
          </h2>
        </div>
        <br />

        {/* Tutors Config Fields */}
        <h3> {t('SchoolConfig.showTutorFormFields')} </h3>
        {fullSchoolConfig && tutorKeys && (
          <>
            {tutorKeys.map((key: string) => (
              <div className={styles.configItem} key={key}>
                <div className={`row ${styles.centered}`}>
                  <label
                    className="label col xs-12 s-9"
                    onClick={() => {
                      const config: SimpleSchoolConfig = {
                        ...simpleSchoolConfig,
                        [key]: !simpleSchoolConfig[key],
                      };
                      setSimpleSchoolConfig(config);
                    }}
                  >
                    {t(`SchoolConfig.${key}`)}
                  </label>
                  <label className="col xs-12 s-3">
                    <div className={styles.switchContainer}>
                      <Switch
                        key={uuidv4()}
                        className={styles.status}
                        hasText
                        textPosition="left"
                        falseTitle={t('SchoolConfig.no')}
                        trueTitle={t('SchoolConfig.yes')}
                        size="medium"
                        value={simpleSchoolConfig[key]}
                        onChange={() => {
                          const config: SimpleSchoolConfig = {
                            ...simpleSchoolConfig,
                            [key]: !simpleSchoolConfig[key],
                          };
                          setSimpleSchoolConfig(config);
                        }}
                      />
                    </div>
                  </label>
                </div>
              </div>
            ))}
          </>
        )}
        <br />

        {/* Legal Tutors Config Fields */}
        <h3> {t('SchoolConfig.showLegalTutorFormFields')} </h3>
        {fullSchoolConfig && legalTutorKeys && (
          <>
            {legalTutorKeys.map((key: string) => (
              <div className={styles.configItem} key={key}>
                <div className={`row ${styles.centered}`}>
                  <label
                    className="label col xs-12 s-9"
                    onClick={() => {
                      const config: SimpleSchoolConfig = {
                        ...simpleSchoolConfig,
                        [key]: !simpleSchoolConfig[key],
                      };
                      setSimpleSchoolConfig(config);
                    }}
                  >
                    {t(`SchoolConfig.${key}`)}
                  </label>
                  <label className="col xs-12 s-3">
                    <div className={styles.switchContainer}>
                      <Switch
                        key={uuidv4()}
                        className={styles.status}
                        hasText
                        textPosition="left"
                        falseTitle={t('SchoolConfig.no')}
                        trueTitle={t('SchoolConfig.yes')}
                        size="medium"
                        value={simpleSchoolConfig[key]}
                        onChange={() => {
                          const config: SimpleSchoolConfig = {
                            ...simpleSchoolConfig,
                            [key]: !simpleSchoolConfig[key],
                          };
                          setSimpleSchoolConfig(config);
                        }}
                      />
                    </div>
                  </label>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      <div>
        <Button
          fullWidth
          size="large"
          isLoading={isLoading}
          onClick={() => {
            setIsLoading(true);
            setTimeout(() => {
              saveSchoolConfigData();
              setIsLoading(false);
            }, 2000);
          }}
        >
          {t('SchoolConfig.saveConfig')}
        </Button>
      </div>

      <RequestUpdateDataModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAccept={() => {
          // If OK, then forece tutors to update their data on login
          onRequireTutorsToUpdateData();
        }}
      />
    </>
  );
};
// You can add another School Config components here...
