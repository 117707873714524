import React from 'react';
import { useEdooImporter } from '../../data/hooks/useEdooImporter';
import { EdooImporterSteps } from '../../settings';
import TemplateDownloadButton from './TemplateDownloadButton';
import FileUploader from './FileUploader';

// import styles from './fileUploaderPlaceholder.module.scss';


type BulkUploadingDataPropTypes = {
    onUploadFileSuccess?: () => void,
}

const BulkUploadingData: React.FunctionComponent<BulkUploadingDataPropTypes> = ({
  // onUploadFileSuccess,
}: BulkUploadingDataPropTypes) => {
  const { setFileData, setStep } = useEdooImporter();

  return (
    <div>
      <TemplateDownloadButton />
      <FileUploader 
        onFileUpload={(fileData: Object[]) => {
          setFileData && setFileData(fileData);
          // If user uploads a new file, We must set this step as current step
          setStep && setStep(EdooImporterSteps.UploadFile)
        }}
      />
    </div>
  );
}

export default BulkUploadingData;
