import React from 'react';

import {
  CircleImage,
  ElementInfo,
  ElementInnerContainer,
  ElementWithImage,
  lightTheme,
  OPTION_TYPE,
  SubElement,
} from '@nahualventure/paper-ui';

type UserSearchRenderItemPropsType = {
  option: OPTION_TYPE;
  idx: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemProps?: any;
  extraClass?: string;
  showSubLabel?: boolean;
};

import styles from './userSearchRenderItem.module.scss';

export const UserSearchRenderItem: React.FunctionComponent<UserSearchRenderItemPropsType> = ({
  option,
  idx,
  itemProps,
  extraClass = '',
  showSubLabel = false,
}: UserSearchRenderItemPropsType) => {
  const itemID = typeof option === 'string' || option instanceof String ? idx : option.value;

  const hasImageURL =
    typeof option === 'string' || option instanceof String ? false : option.imageUrl;

  return (
    <ElementWithImage
      key={itemID}
      className={`${styles['render-item']} ${extraClass}`}
      theme={lightTheme}
      tint="primary"
      hover="primary"
      {...itemProps}
    >
      <ElementInnerContainer>
        {hasImageURL && (
          <CircleImage
            size={'small'}
            ringColor={'primary'}
            variant={'ring'}
            src={typeof option === 'string' || option instanceof String ? '' : option.imageUrl}
            alt={typeof option === 'string' || option instanceof String ? '' : option.label}
          />
        )}
        <ElementInfo className={`${hasImageURL ? '' : styles['render-item-info']} `}>
          <label className={styles['render-item-label']}>
            {typeof option === 'string' || option instanceof String ? option : option.label || ''}
          </label>
          {showSubLabel && (
            <SubElement className={styles['render-item-sublabel']}>
              {typeof option === 'string' || option instanceof String
                ? option
                : option.subLabel || ''}
            </SubElement>
          )}
        </ElementInfo>
      </ElementInnerContainer>
    </ElementWithImage>
  );
};
