import React from 'react';
import Portal from '../../components/Portal';
import { StageMailingConfig } from '../StageConfig';
import { RouteParamsType } from '../MultipageRoute';

const ConfigUpdateTutorInfo: React.FunctionComponent<RouteParamsType> = (props : RouteParamsType) => {
  return (
    <Portal selector={'#stage-mailing-config'}>
        <StageMailingConfig {...props} />
    </Portal>
  );
}

export default ConfigUpdateTutorInfo;