import React, { useContext, useEffect, useState } from 'react';

import { Loader, Modal } from '@nahualventure/paper-ui';
import { Trans, useTranslation } from 'react-i18next';

import { GenerateDocument } from './GenerateDocument';
import { RouteParamsType } from '../../MultipageRoute';
import { UserItem } from './UserItem';
import DjangoRedirect from '../../DjangoRedirect';
import Portal from '../../Portal';

import { ChildDocumentType, ChildType, ContextType } from '../../../data/types';
import { fetchConfigSuccessDataUpdateModal } from '../../../adapters/schools';
import { getChildren } from '../../../adapters/tutorProfiles';
import { getDocumentsPerStudent } from '../../../adapters/documents';
import { GlobalEdooContext } from '../../../data/globalEdooContext';
import { shoutError } from '../../../utils';

import styles from './successfulDataUpdateModal.module.scss';

type SuccessfulDataUpdateModalProps = {
  routeParams: RouteParamsType;
};

export const SuccessfulDataUpdateModal: React.FunctionComponent<SuccessfulDataUpdateModalProps> = ({
  routeParams,
}: SuccessfulDataUpdateModalProps) => {
  const { t: translation } = useTranslation();
  const { session } = useContext<ContextType>(GlobalEdooContext);

  const [isOpen, setIsOpen] = useState(false);
  const [modalSettings, setModalSettings] = useState({
    isLoading: true,
    textToDisplay: '' as string,
    allowToDownloadDocuments: false as boolean,
    username: '' as string,
  });
  const [childrenData, setChildrenData] = useState({
    isLoading: true,
    data: [] as ChildType[],
  });

  const verifyIsNecessaryOpenModal = () => {
    location.href.includes('#new-successfully-updated') && setIsOpen(true);
  };

  const printDocumentsButton = document.getElementById('printDocumentsButton');
  if (printDocumentsButton) {
    printDocumentsButton.addEventListener('click', () => {
      setIsOpen(true);
    });
  }

  const fetchModalSettings = async () => {
    if (!session) return;
    const jwtToken = session.token;

    const username = routeParams.username ? routeParams.username.toString() : session.user.username;

    try {
      const response = await fetchConfigSuccessDataUpdateModal(jwtToken);
      if (response.error) throw new Error(response.error);

      setModalSettings({
        isLoading: false,
        textToDisplay: response.textToDisplayInDataUpdate,
        allowToDownloadDocuments: response.allowToDownloadDocuments,
        username,
      });
      if (!response.allowToDownloadDocuments) return;

      const children = await getChildren(jwtToken, username);

      await Promise.all(
        children.map(async (child) => {
          if (child.level && child.level.stage) {
            const documents = await getDocumentsPerStudent(jwtToken, child.level.stage.uuid);
            if (documents.length) {
              let childDocuments: ChildDocumentType[] = [];
              for (const { id, name } of documents) {
                childDocuments = [
                  ...childDocuments,
                  {
                    id,
                    text: name,
                    show: true,
                    redirectTo: {
                      Component: GenerateDocument,
                      RouterArgs: { documentId: id, childUuid: child.uuid },
                    },
                  },
                ];
              }
              child.documents = childDocuments;
            }
          }
        }),
      );

      setChildrenData({
        isLoading: false,
        data: children,
      });
    } catch (responseError) {
      shoutError(translation('SuccessfulDataUpdate.errorGettingOfficialDocuments'));
    }
  };

  useEffect(() => {
    if (true) return;
    verifyIsNecessaryOpenModal();
    fetchModalSettings();
  }, [session]);

  return (
    <Portal selector={'#new-successfully-updated'}>
      <Modal open={isOpen} className={styles.rootModal} size="normal">
        <div className={styles.modal}>
          <div className={`${styles.closeButton} popup popup-alert`}>
            <button
              title="Close (Esc)"
              className="mfp-close"
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>

          <section className={styles.body}>
            <div className={styles.title}>
              <Trans i18nKey="SuccessfulDataUpdate.modalTitle">
                Download the <span className={styles.accent}>official documentation</span>
              </Trans>
            </div>

            {modalSettings.isLoading && (
              <div
                style={{
                  minWidth: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Loader size="medium" text="" textPosition="bottom" tint="primary" />
              </div>
            )}

            {modalSettings.textToDisplay && (
              <div
                className={styles.successfullyUpdatedExplanation}
                dangerouslySetInnerHTML={{ __html: modalSettings.textToDisplay }}
              ></div>
            )}

            {modalSettings.allowToDownloadDocuments && (
              <div className={`row ${styles.usersSection}`}>
                {childrenData.isLoading && (
                  <div
                    style={{
                      minWidth: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Loader size="small" text="" textPosition="bottom" tint="primary" />
                  </div>
                )}

                {childrenData.data.map(({ id, user, level, documents }: ChildType) => (
                  <div className="col xs-12" key={id}>
                    <UserItem
                      name={user.name}
                      image={user.tinyProfilePicture}
                      sublabel={`${level?.name} ${level?.stage?.name}`}
                      items={
                        documents || [
                          {
                            text: translation('SuccessfulDataUpdate.withoutDocuments'),
                            show: true,
                          },
                        ]
                      }
                      disableDocumentPrinting={documents ? false : true}
                    />
                  </div>
                ))}
              </div>
            )}
          </section>

          <footer>
            <p>
              <Trans i18nKey="SuccessfulDataUpdate.modalFooter">
                You can also download the documents by{' '}
                <DjangoRedirect
                  className={styles.accent}
                  absUrlName="tutorprofile"
                  args={{ username: modalSettings.username }}
                >
                  accessing your profile
                </DjangoRedirect>
                .
              </Trans>
            </p>
          </footer>
        </div>
      </Modal>
    </Portal>
  );
};
