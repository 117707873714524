import React, { useEffect, useState } from 'react';

import { Button, CircleImage, Icon, PopMenu } from '@nahualventure/paper-ui';
import { Trans } from 'react-i18next';

import { useGenerateDocumentUrl } from './useGenerateDocumentUrl';

import styles from './successfulDataUpdateModal.module.scss';

type UserItemPropsType = {
  name: string;
  image: string;
  sublabel: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Array<{ text: string; show: boolean; redirectTo?: any }>;
  disableDocumentPrinting: boolean;
};

export const UserItem: React.FunctionComponent<UserItemPropsType> = ({
  name,
  image,
  sublabel,
  items,
  disableDocumentPrinting,
}: UserItemPropsType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { downloadUrl, isLoadingDocumentUrl, setDataDocumentUrl } = useGenerateDocumentUrl({
    documentId: '',
    childUuid: '',
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (downloadUrl) {
      window.open(downloadUrl, '_blank', 'noopener,noreferrer');
      return;
    }

    if (!Boolean(anchorEl)) setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getDefaultURL = () => {
    const { documentId, childUuid } = items[0].redirectTo.RouterArgs;
    setDataDocumentUrl({ documentId, childUuid });
  };

  useEffect(() => {
    if (!disableDocumentPrinting && items.length == 1) getDefaultURL();
  }, []);

  return (
    <div className={`row ${styles.userContainer}`}>
      <div className={`col xs-12 s-2 m-2 ${styles.centered}`}>
        <CircleImage size="small" variant="default" src={image} alt={name} />
      </div>

      <div className={`col xs-12 s-10 m-6 ${styles.textContainer} ${styles.centered}`}>
        <span>{name}</span>
        <small className={styles.subLabel}>{sublabel}</small>
      </div>

      <div className={`col xs-12 s-12 m-4 ${styles.centered}`}>
        <Button
          color="success"
          colorVariant="main"
          size="medium"
          startIcon={<Icon iconName="paperPlane" />}
          variant="default"
          onClick={handleClick}
          disabled={disableDocumentPrinting && !isLoadingDocumentUrl}
          isLoading={isLoadingDocumentUrl}
        >
          <Trans i18nKey="SuccessfulDataUpdate.print">Print</Trans>
        </Button>

        {items.length > 1 && (
          <PopMenu
            anchorElement={anchorEl}
            className={styles.popMenu}
            handleClose={handleClose}
            open={Boolean(anchorEl)}
            placement="right"
            showArrow
            items={items}
          >
            &nbsp;
          </PopMenu>
        )}
      </div>
    </div>
  );
};
