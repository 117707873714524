import React, { createContext, useEffect, Context, useState } from 'react';

import { fetchUser } from '../adapters/customUser';
import { SessionType, ContextType, GlobalEdooProviderPropsType } from './types';
// import useLocalStorage from './hooks/localStorage';

// const SESSION_KEY = 'session';
// const CSRF_TOKEN_KEY = 'lastCsrftoken';

const getCsrfToken = () =>
  document.cookie
    .split(';')
    .filter((x) => x.includes('csrftoken'))[0]
    .split('=')[1];

export const GlobalEdooContext: Context<ContextType> = createContext({});

const GlobalEdooProvider: React.FunctionComponent<GlobalEdooProviderPropsType> = (
  props: GlobalEdooProviderPropsType,
) => {
  const [session, setSession] = useState<SessionType | undefined>(undefined);
  const [csrftoken, setCsrftoken] = useState<string>(getCsrfToken());

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchUser(csrftoken).catch(() => setSession(undefined));
      if (!!response) setSession(response);
    };

    fetchData();
  }, [csrftoken]);

  return (
    <GlobalEdooContext.Provider
      value={{
        session,
        setSession,
        csrftoken,
        setCsrftoken,
      }}
      {...props}
    />
  );
};

export default GlobalEdooProvider;
