import React from 'react';

// import { fetchUser } from '../../adapters/customUser';
// import { GlobalEdooContext } from '../../data/globalEdooContext';
// import { ContextType } from '../../data/types';

const ChangeLanguage: React.FunctionComponent = () => {
  // const { csrftoken, setSession } = useContext<ContextType>(GlobalEdooContext);

  // useEffect(() => {
  //   if (!!setSession && csrftoken !== undefined) {
  //     const fetchData = async () => {
  //       const response = await fetchUser(csrftoken).catch(() => setSession(undefined));
  //       if (!!response) setSession(response);
  //     };

  //     fetchData();
  //   }
  // }, [csrftoken]);

  return <></>;
};

export default ChangeLanguage;
