import React, { useEffect, useRef } from 'react';
import styles from './ProgressCircle.module.scss';

interface ProgressCircleProps {
  stroke: number;
  radius: number;
  progress: number;
  twinkle: boolean;
}

export const ProgressCircle: React.FC<ProgressCircleProps> = ({
  stroke,
  radius,
  progress,
  twinkle,
}: ProgressCircleProps) => {
  const circleRef = useRef<SVGCircleElement>(null);

  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const offset = circumference - (progress / 100) * circumference;

  useEffect(() => {
    if (circleRef.current) {
      circleRef.current.style.strokeDashoffset = `${offset}`;
    }
  }, [progress, offset]);

  return (
    <svg height={radius * 2} width={radius * 2}>
      <circle
        strokeWidth={stroke}
        stroke="#cdcdcd"
        fill="transparent"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        ref={circleRef}
        className={twinkle ? `progress ${styles['twinkle']}` : 'progress'}
        strokeWidth={stroke + 0.4}
        stroke="#019c97"
        fill="transparent"
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset: offset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};
