import React from 'react';

import { CircleImage, Icon, OPTION_TYPE } from '@nahualventure/paper-ui';

import styles from './userSelected.module.scss';

type UserSelectedPropsType = {
  itemID: string | number;
  option: OPTION_TYPE;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onReset: Function;
};

export const UserSelected: React.FunctionComponent<UserSelectedPropsType> = ({
  itemID,
  option,
  onReset,
}: UserSelectedPropsType) => {
  return (
    <div className={styles.userSelectedContainer}>
      <div className={styles.imageContainer}>
        <CircleImage
          size="small"
          ringColor="primary"
          variant="ring"
          src={typeof option === 'string' || option instanceof String ? '' : option.imageUrl}
          alt={typeof option === 'string' || option instanceof String ? '' : option.label}
        />
      </div>

      <span className={styles.nameContainer}>
        {typeof option === 'string' || option instanceof String ? option : option.label || ''}
      </span>

      <div className={styles.clearContainer}>
        <Icon
          iconSize="sm"
          iconName="times"
          className={styles.clearIcon}
          onClick={() => onReset(itemID)}
        />
      </div>
    </div>
  );
};
