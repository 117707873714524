import { AsyncFunction, SimpleResponse } from './types';
import { ChildType } from '../data/types';
import { TutorProfiles } from '../api';

export const requireTutorsToUpdateData: AsyncFunction<string, SimpleResponse> = async (
  jwtToken: string,
) => {
  return await TutorProfiles.custom.requireTutorsToUpdateData({ token: jwtToken });
};

export const getChildren: AsyncFunction<string, ChildType[]> = async (
  jwtToken: string,
  username: string,
) => {
  return await TutorProfiles.custom.getChildren({
    token: jwtToken,
    filters: { username, 'full-info': true },
  });
};
