import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import NavbarLink from '../NavbarLink';
import Portal from '../Portal';
import Icon, { iconKeyType } from '../Icon';
import PopMenuOption from '../PopMenuOption';
import { GlobalEdooContext } from '../../data/globalEdooContext';
import { ContextType } from '../../data/types';

import styles from './UserMainActions.module.scss';

type optionType = {
  icon: iconKeyType;
  text: string;
  redirect: string;
  show: boolean;
};

const UserMainActions: React.FunctionComponent = () => {
  const { session } = useContext<ContextType>(GlobalEdooContext);
  const { t } = useTranslation();

  const options: optionType[] = [
    {
      icon: 'cogs',
      text: t('UserMainActions.configuration'),
      redirect: 'configuration_dashboard',
      show: true,
    },
    {
      icon: 'building',
      text: t('UserMainActions.schoolConfiguration'),
      redirect: 'school_configuration_dashboard',
      show: session?.user
        ? session?.user.isSuperuser ||
          session?.user.groups.findIndex((g) => g === 'PRINCIPALS') !== -1
        : false,
    },
    {
      icon: 'signOutAlt',
      text: t('UserMainActions.logOut'),
      redirect: 'logout',
      show: true,
    },
  ];

  return (
    <Portal selector={'#user-main-actions'}>
      <NavbarLink
        icon={
          <span className={styles.Icon}>
            <Icon iconName={'caretDown'} className={styles.IconCog} />
            <Icon iconName={'cog'} />
          </span>
        }
        menuOptions={
          <div className={styles.manuContainer}>
            {options
              .filter((op) => op.show)
              .map((op, index) => (
                <PopMenuOption
                  key={index}
                  icon={<Icon iconName={op.icon} />}
                  text={op.text}
                  redirectTo={{ absUrlName: op.redirect }}
                />
              ))}
          </div>
        }
      />
    </Portal>
  );
};

export default UserMainActions;
