import React, { useEffect, useState } from 'react';
import styles from './student.module.scss';

interface Props {
  name: string;
  image: string;
  checked?: boolean;
  onChange: (checked: boolean) => void;
}

const Student = ({ name, image, checked: outsideCheck = false, onChange }: Props) => {
  const [checked, setChecked] = useState(outsideCheck);

  useEffect(() => {
    setChecked(outsideCheck);
  }, [outsideCheck]);

  // useEffect(() => {
  //   if (onChange) {
  //     onChange(checked);
  //   }
  // }, [checked]);

  const toggleChecked = () => {
    onChange(!checked);
    // setChecked(prev => {
    //   const checked = !prev;
    //   onChange && onChange(checked);
    //   return checked;
    // });
  }

  return (
    <div className={styles['student-row']}>
      <input type="checkbox" checked={checked} onChange={toggleChecked} />
      <img src={image} alt="student" />
      <label onClick={toggleChecked}>{name}</label>
    </div>
  );
}

export default Student;
