import React from 'react';

import { redirectToDjangoUrlType } from '../../utils/urls';
import DjangoRedirect from '../DjangoRedirect';

import styles from './PopMenuOption.module.scss';

type popMenuOptionPropsType = {
  text: string;
  icon?: React.ReactNode;
  redirectTo?: redirectToDjangoUrlType;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const PopMenuOption: React.FunctionComponent<popMenuOptionPropsType> = ({
  icon,
  text,
  redirectTo,
  onClick,
}: popMenuOptionPropsType) => {
  const content = (
    <>
      {icon && icon} <span>{text}</span>
    </>
  );

  return (
    <div className={`${styles.PopMenuOption} ${!redirectTo && styles.Parent}`} onClick={onClick}>
      {redirectTo ? (
        <DjangoRedirect {...redirectTo} className={styles.Parent}>
          {content}
        </DjangoRedirect>
      ) : (
        content
      )}
    </div>
  );
};

export default PopMenuOption;
