import React, { useContext, useEffect } from 'react';
// import { createPortal } from 'react-dom';

import { GlobalEdooContext } from '../../data/globalEdooContext';
import { ContextType } from '../../data/types';

// import Button from '../Button';

// const AccessButton = () => {
//   const element = document.querySelector('#access-button');

//   return element
//     ? createPortal(
//         <>
//           <Button title="click component!" />
//         </>,
//         element,
//       )
//     : null;
// };

// const TestMessage = () => {
//   const element = document.querySelector('#test-message');

//   return element
//     ? createPortal(
//         <>
//           <p>componente de prueba</p>
//         </>,
//         element,
//       )
//     : null;
// };

const Login: React.FunctionComponent = () => {
  const { setSession } = useContext<ContextType>(GlobalEdooContext);

  useEffect(() => {
    if (!!setSession) {
      setSession(undefined);
    }
  });

  return <></>;
};

export default Login;
