// @flow
import React from 'react';
import Icon from '../../Icon';
import { useTranslation } from 'react-i18next';

import pictureURL from '../../../assets/images/drop_file_here.png'
import styles from './dropFileZonePlaceholder.module.scss';

type DropFileZonePropsType = {
  acceptedFiles: File[],
  isDragActive: boolean,
  onSelectFile: () => void,
};

const DropFileZonePlaceholder: React.FunctionComponent<DropFileZonePropsType> = ({
  onSelectFile,
  acceptedFiles,
  isDragActive,
}: DropFileZonePropsType) => {
  const { t } = useTranslation();
  
  return (
    <>
      {
        !isDragActive && acceptedFiles && acceptedFiles.length > 0 ? (
          <div className={styles.fileContainer}>
            <div className={styles.selectedFile}>
              <Icon
                iconSize={'2x'}
                tint={'primary'}
                iconName={'fileExcel'}
              />
              <span className={styles.fileName}>
                {acceptedFiles[0].name}
              </span>
            </div>
            <span className={styles.upload} onClick={() => onSelectFile()}>
              { t('EdooImporter.selectAnotherFile') }
            </span>
          </div>
        ) : (
          <div className={styles.dropFileZone}>
            <div className={styles.placeholder}>
              <img src={pictureURL} alt="alt" />
              { t('EdooImporter.dropFileHere') } 
              <span className={styles.upload} onClick={() => onSelectFile()}>
                { t('EdooImporter.selectFile') }
              </span>
            </div>
          </div>
        )
      }
    </>
  )
};

export default DropFileZonePlaceholder;
