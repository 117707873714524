import React from 'react';
import { createPortal } from 'react-dom';

type portalPropsType = {
  children?: React.ReactNode;
  selector: string;
};

const Portal = ({
  children,
  selector,
}: portalPropsType) => {
  const $container = document.querySelector(selector);
  return (
    <>
      {$container && createPortal(children, $container)}
    </>
  )
};

export default Portal;
