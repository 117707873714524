import { RESTfulAPI, Resource } from '../lib/http';
import { API_BASE_URL, IS_API_IN_DEV_MODE } from '../settings';
/* import HTTPError from '../lib/HTTPError'; */

export const api = new RESTfulAPI(API_BASE_URL, 'api/v3', IS_API_IN_DEV_MODE); // process.env.NODE_ENV !== 'production');

/* export const login = (email: string, password: string) => (
  post({
    url: api.getURL('token-auth'),
    data: { email, password },
    headers: {
      'Content-Type': 'application/json',
    },
  }).catch((error) => {
    const { isPlain, statusCode } = error;

    if (isPlain) {
      throw new HTTPError({
        statusCode,
        data: 'Error del servidor',
      });
    }

    throw error;
  })
); */

export const CustomUsers = new Resource({
  name: 'custom-users',
  api,
  customization: {
    getUserData: {
      method: 'POST',
      urlPart: 'get-user-data',
      isDetail: false,
    },
  },
});

export const Stages = new Resource({
  name: 'stages',
  api,
  customization: {
    getConfigWithMeta: {
      method: 'GET',
      urlPart: 'config-with-metadata',
      isDetail: true,
    },
    saveStageConfiguration: {
      method: 'POST',
      urlPart: 'save-stage-configuration',
      isDetail: true,
    },
  },
});

export const Schools = new Resource({
  name: 'schools',
  api,
  customization: {
    saveSchoolConfiguration: {
      method: 'POST',
      urlPart: 'default/save-school-configuration',
      isDetail: false,
    },
    getSchoolConfiguration: {
      method: 'GET',
      urlPart: 'default/school-configuration',
      isDetail: false,
    },
    getConfigSuccessDataUpdateModal: {
      method: 'GET',
      urlPart: 'default/configuration-successful-data-update-modal',
      isDetail: false,
    },
  },
});

export const TutorProfiles = new Resource({
  name: 'tutor-profiles',
  api,
  customization: {
    requireTutorsToUpdateData: {
      method: 'POST',
      urlPart: 'require-tutors-update-info',
      isDetail: false,
    },
    getChildren: {
      method: 'GET',
      urlPart: 'children',
      isDetail: true,
    },
  },
});

export const Cycles = new Resource({
  name: 'cycles',
  api,
  customization: {
    getActiveCycles: {
      method: 'GET',
      urlPart: 'active',
      isDetail: false,
    },
  },
});

export const StudentProfiles = new Resource({
  name: 'student-profiles',
  api,
  customization: {
    uploadInBulk: {
      method: 'POST',
      urlPart: 'upload-in-bulk',
      isDetail: false,
    },
    getSheetHeadersForBulkUpload: {
      method: 'GET',
      urlPart: 'sheet-headers-for-upload-in-bulk',
      isDetail: false,
    },
  },
});

export const Documents = new Resource({
  name: 'docs',
  api,
  customization: {
    getAllDocuments: {
      method: 'GET',
      urlPart: 'documents',
      isDetail: false,
    },
    generateDocumentUrl: {
      method: 'POST',
      urlPart: ({ documentId }: { [key: string]: string }) => `document/${documentId}/generate`,
      isDetail: false,
    },
  },
});

export const Notifications = new Resource({
  name: 'notifications',
  api,
  customization: {
    sendNotification: {
      method: 'POST',
      urlPart: '',
      isDetail: false,
      appendSlash: false,
    }
  }
});

export const Files = new Resource({
  name: 'files',
  api,
  customization: {
    generateUploadUrl: {
      method: 'POST',
      urlPart: '',
      isDetail: false,
      appendSlash: false,
    }
  }
});

export const Sections = new Resource({
  name: 'sections',
  api,
  customization: {
    getStudents: {
      method: 'GET',
      urlPart: ({ id }: { [key: string]: string }) => `${id}/students`,
      isDetail: false
    }
  }
});

export const OfficialSections = new Resource({
  name: 'official-sections',
  api,
  customization: {
    getStudents: {
      method: 'GET',
      urlPart: ({ id }: { [key: string]: string }) => `${id}/students`,
      isDetail: false
    }
  }
});

export const Search = new Resource({
  name: 'search',
  api,
  customization: {
    searchStaff: {
      method: 'GET',
      urlPart: 'staff-search',
      isDetail: false,
    },
    searchStructure: {
      method: 'GET',
      urlPart: 'structure-search',
      isDetail: false,
    },
    searchOmni: {
      method: 'GET',
      urlPart: 'omni-search',
      isDetail: false,
    },
  },
});
