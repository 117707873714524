const en = {
  UserMainActions: {
    configuration: 'Configuration',
    schoolConfiguration: 'School configuration',
    logOut: 'Logout',
  },
  SchoolConfig: {
    allowSkipUpdateData: 'Allow to skip updating data',
    administrativeUsersToNotify: 'Users to be notified when a tutor completes the data update',
    headerOnUpdateCompletion: 'When the update is complete',
    textToDisplayInDataUpdate: 'Text to display',
    allowToDownloadDocuments: 'Allow to download documents',
    requestDataUpdate: 'Request data update',
    requestDataUpdateDescription: 'Request tutors to update their data in Edoo login',
    requestDataUpdateButton: 'Request update',
    requestDataUpdateModalTitle: 'Request tutors to <1>update their data</1>?',
    requestDataUpdateModalContent: 'This action will show a series of forms to the tutors when they login to Edoo, from where they can <1>validate or update personal information of them and their students.</1>',
    saveConfig: 'Save Configuration',
    sectionDataUpdate: 'Data Update',
    sectionFormUpdate: 'Update Forms',
    showTutorFormFields: 'Show Tutors the update forms for:',
    showLegalTutorFormFields: 'Show Legal Tutors the update forms for:',
    modalAccept: 'Continue',
    modalDecline: 'Cancel',
    yes: 'Yes',
    no: 'No',
    // General Fields
    // allowSkipTutorUpdateData: '',
    // Tutor Fields
    showPersonalDataUpdate: 'Personal Data',
    showProfessionalDataUpdate: 'Professional Data',
    showFiscalDataUpdate: 'Fiscal Data',
    showPickupMethodsDataUpdate: 'Pickup Methods',
    showContactsDataUpdate: 'Contacts Data',
    showMedicalDataUpdate: 'Medical Data',
    showLegalTutorsDataUpdate: 'Legal Tutors Data',
    // Legal Tutor Fields
    showPersonalDataUpdateLegalTutor: 'Personal Data',
    showProfessionalDataUpdateLegalTutor: 'Professional Data',
    showFiscalDataUpdateLegalTutor: 'Fiscal Data',
    showPickupMethodsDataUpdateLegalTutor: 'Pickup Methods',
    showContactsDataUpdateLegalTutor: 'Contacts Data',
    showMedicalDataUpdateLegalTutor: 'Medical Data',
    showLegalTutorsDataUpdateLegalTutor: 'Legal Tutors Data',
    saveSchoolConfigSuccess: 'Config saved successfully',
    saveSchoolConfigFail: 'Error on config update',
  },
  StageConfig: {
    mailingSection: 'Communication',
    mailingCustomSender: 'Outgoing email custom senders',
    mailingCustomSenderDescription: 'Use as sender the email address of the user who sends the email.',
    // form fields (equivalent camelCase)
    useUserEmailInDisciplinaryFaults: 'Disciplinary Faults',
    useUserEmailInEvents: 'Events',
    useUserEmailInNotifications: 'Notifications',
    useUserEmailToSendCommentsAsSender: 'Homework Comments',
    useUserEmailInAssistance: 'Assists',
    yes: 'Yes',
    no: 'No',
    saveConfig: 'Save Configuration',
    saveStageConfigSuccess: 'Stage Config saved successfully',
    saveStageConfigFail: 'Error on stage config update',
  },
  EdooImporter: {
    description: 'Import your data using a CSV or XLSX file',
    dataType: 'Select the type of data you will upload',
    dataPrepare: 'Prepare the data to upload',
    uploadCSV: 'Upload your CSV file',
    dataReview: 'Review the data that you will save in Edoo',
    sampleTemplate: 'Sample Template',
    uploadAction: 'Import data',
    selectDataType: 'Select Data type',
    selectCycle: 'Cycle',
    preparationsDescription: 'To load students you must have previously created the <strong>Levels</strong>, <strong>Courses</strong>, <strong>Sections</strong> and <strong>Cycles</strong> necessary.',
    assignCycle: 'Cycle to assign these students:',
    dropFileHere: 'Drag n drop file here or ',
    selectFile: 'Select your file',
    selectAnotherFile: 'Select another file',
    templateDownload: 'Template',
    nextStep: 'Continue',
    validationWarning: 'Check that the data you are about to create in Edoo is correct. You are about to create: <1>{{ studentsCount }}</1> student(s) and <3>{{ tutorsCount }}</3> tutor(s).',
    validationInvalidRowsError: 'Cannot upload data. {{ rowErrorsCount }} rows were found with empty required fields or invalid optional data.',
    validationInvalidHeadersError: 'Check that the data you are about to create has the correct headers.',
    // validationInvalidTutorError: 'Check that required tutors fields are not partially filled.',
    validationError: 'An error occurred while validating uploaded data',
    importData: 'Import Data',
    reuploadCSV: 'Re-upload CSV',
    uploadSuccessful: 'Data imported successfully',
    resetImporterForm: 'Import data again',
  },
  EdooImporterDataTypes: {
    students: 'Students',
    staffs: 'Staffs',
    attitudinals: 'Attitudinals',
    levels: 'Levels',
    courses: 'Courses',
  },
  SuccessfulDataUpdate: {
    modalTitle: 'Download the <1>official documentation</1>',
    modalFooter: 'You can also download the documents by <2>accessing your profile</2>.',
    print: 'Print',
    withoutDocuments: 'No documents to download',
    errorGettingOfficialDocuments: 'An error occurred while getting the official documentation',
    errorGettingDocuments: 'An error occurred while getting the documents',
    loading: 'Please wait, we are generating your document',
  },
  SidePanel: {
    notificationTitle: 'Title',
    notificationDescription: 'Description',
    notificationTitleGroup: 'Select the groups to send the notification',
    removeFile: 'Remove file',
    searchBy: 'Search by',
    searchByStructure: 'Structure',
    searchByPerson: 'Person',
    submit: 'Submit notification',
    groups: {
      student: 'Student',
      students: 'Students',
      parents: 'Parents',
      staff: 'Staff',
      teachers: 'Teachers',
    },
    editStudentListFrom: 'Edit student list from',
    editStudentList: 'Edit student list',
    allStudents: 'All students'
  },
};

export default en;
