import React from 'react';
import { redirectToDjangoUrlType, djangoUrls } from '../../utils/urls';

type djangoRedirectPropsType = {
  children: React.ReactNode;
  className?: string;
} & redirectToDjangoUrlType;

const DjangoRedirect: React.FunctionComponent<djangoRedirectPropsType> = ({
  children,
  absUrlName,
  args,
  className,
}: djangoRedirectPropsType) => {
  const url = djangoUrls(absUrlName)(args);

  return (
    <a href={url} className={className}>
      {children}
    </a>
  );
};

export default DjangoRedirect;
