import React, { useRef, useState } from 'react';
import { PopMenu } from '@nahualventure/paper-ui';

import styles from './NavbarLink.module.scss';

type NavbarLinkPropsType = {
  icon?: React.ReactNode;
  text?: string;
  menuOptions?: React.ReactNode;
};

const NavbarLink: React.FunctionComponent<NavbarLinkPropsType> = ({
  icon,
  text,
  menuOptions,
}: NavbarLinkPropsType) => {
  const link = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <span
        className={styles.NavbarLink}
        ref={link}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {icon}
        {text && <span>{text}</span>}
      </span>
      {menuOptions && open && (
        <PopMenu
          showArrow={true}
          open={open}
          placement="bottom-end"
          anchorElement={link.current}
          handleClose={(state: boolean) => {
            setOpen(state);
          }}
          anchorOffset={20}
          className={styles.popMenu}
        >
          {menuOptions}
        </PopMenu>
      )}
    </>
  );
};

export default NavbarLink;
