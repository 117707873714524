import React, { useContext } from 'react';

import { MultipageRouterContext, MultipageRouterContextType } from '../MultipageRouter';

type MultipageRouteProps = {
  route: string;
  Component?: any;
  flags?: string;
};

export type RouteParamsType = {
  [key: string]: number | string;
}

const MultipageRoute: React.FunctionComponent<MultipageRouteProps> = ({
  route,
  Component,
  flags = 'i',
}: MultipageRouteProps) => {
  // Parse route params as regex, just to check URL
  const regExp = new RegExp(route.replace(/:[^\s/]+/g, '([\\w-]+)'), flags);
  const { path } = useContext<MultipageRouterContextType>(MultipageRouterContext);

  if (Component && regExp.test(path)) {
    /*
      Route Params Extract (just doing some Vanilla ExpressJS Route Params here, my friend...)

      const route = "/users/:uid/pictures/:pictureId";
      const path = "/users/123/pictures/456";

      Then, map every param with its value
      > { uid: 123, pictureId: 456 }
    */
    const routeValueMatcher = new RegExp(route.replace(/:[^\s/]+/g, '([\\w-]+)'));
    const routeParamMatcher = new RegExp(':([\\w-]+)', 'i');

    let matchValues = path.match(routeValueMatcher)?.map((value) => value)
    let matchParams = route.match(routeParamMatcher)?.map((param) => param);

    // Map params & values, remove the first item (due to regex: returns the original url as whole match)
    matchValues = matchValues && matchValues.length > 1 ? matchValues.slice(1) : []
    matchParams = matchParams && matchParams.length > 1 ? matchParams.slice(1) : []

    // Check same length
    let routeProps = {};
    if (matchParams.length === matchValues.length) {
      const routeParams = matchParams.reduce(function (result, field, index) {
        const newVal = {
          ...result,
          [field]: matchValues ? matchValues[index] : '',
        }
        return newVal;
      }, {})

      routeProps = {
        routeParams
      };
    }

    return <> <Component {...routeProps} /> </>;
  }

  return null;
};

export default MultipageRoute;
