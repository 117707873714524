import isFunction from 'lodash/isFunction';
import camelcaseKeys from 'camelcase-keys';
import * as axios from 'axios'

import HTTP_STATUS, * as fromStatus from './HTTPStatus';
import HTTPError from './HTTPError';

import { isEmptyObject } from '../utils';

type URLParamsType = { [key: string]: string };
type MockType = {
  response: {
    statusCode: number;
    body: any;
  };
  delay: number;
};
type FetchPromiseResponseType = Promise<any>;
type PostType = {
  url: string;
  data: URLParamsType;
  headers?: URLParamsType;
  files?: Object;
  mock?: MockType;
};
type PutType = PostType;
type PatchType = PostType;
type GetType = {
  url: string;
  headers?: URLParamsType;
  mock?: MockType;
};
type DelType = GetType;

type RequestUrlPartParams = {
  urlPartParams: URLParamsType;
};
type RequestHeadersType = {
  headers: URLParamsType;
};
type RequestTokenType = {
  token?: string;
};
type RequestMockType = {
  mock?: MockType;
};
type RequestCommonType = RequestTokenType &
  RequestHeadersType &
  RequestUrlPartParams &
  RequestMockType;
type FileRequestCommonType = {
  files: Object;
} & RequestCommonType;
type ResourceListType = {
  filters: URLParamsType;
  headers: URLParamsType;
  token?: string;
  mock?: MockType;
};
type DetailResourceType = {
  id: number;
} & RequestCommonType;
type UpdateResourceType = {
  id: number;
  data: URLParamsType;
} & FileRequestCommonType;
type ReplaceResourceType = {
  id: number;
  data: URLParamsType;
} & FileRequestCommonType;
type CreateResourceType = {
  data: URLParamsType;
} & FileRequestCommonType;
type CustomMethodType = {
  data: URLParamsType;
  filters: URLParamsType;
} & FileRequestCommonType;
type CustomDetailMethodType = {
  id: number;
  filters: URLParamsType;
  data: URLParamsType;
} & FileRequestCommonType;

export const delay = (time: number): Promise<unknown> => {
  if (time <= 0) {
    return Promise.resolve({});
  }

  return new Promise((resolve) => setTimeout(resolve, time));
};

export const toQuery = (params: URLParamsType): string =>
  Object.keys(params)
    .filter((key) => typeof params[key] !== 'undefined')
    .map((value) => `${value}=${params[value]}`)
    .join('&');

const primaryResponseHandler = (response: axios.AxiosResponse): Promise<unknown> => {
  const { headers, status } = response || {};
  const statusCode = status;
  const contentType = headers['content-type']?.split(';')[0];

  if (fromStatus.isSuccessful(statusCode)) {
    // No body
    if (status === HTTP_STATUS.NO_CONTENT) {
      return Promise.resolve({});
    }


    // Theorically server returned a JSON
    if (contentType === 'application/json') {
      return Promise.resolve(camelcaseKeys(response.data as any, { deep: true })).catch(() => {
        throw new HTTPError({
          statusCode,
          data: camelcaseKeys(response.data as any, { deep: true }),
          meta: 'Server returned success, but an error occurred while parsing JSON response',
        });
      });
    }

    return response.data.then(() => {
      return {
        text: response.data,
        response,
      };
    });
  }

  // Error
  if (fromStatus.isError(statusCode)) {
    if (contentType === 'application/json') {
      return Promise.resolve(camelcaseKeys(response.data as any, { deep: true }))
        .catch(() => {
          throw new HTTPError({
            statusCode,
            data: camelcaseKeys(response.data as any, { deep: true }),
            meta: 'Server returned a JSON error response, but an error occurred while parsing it',
          });
        })
        .then(() => {
          throw new HTTPError({
            statusCode,
            data: camelcaseKeys(response.data as any, { deep: true }),
            meta: 'Server returned a JSON error response',
          });
        });
    }

    return response.data.then((data: any) => {
      throw new HTTPError({
        statusCode,
        data,
        meta: 'Server returned a PLAIN error response',
      });
    });
  }

  // TODO: don't know how to handle 1xx and 3xx responses :(
  return Promise.resolve({});
};

export const throwTimeout = (err: axios.AxiosError) => {
  throw new HTTPError({
    statusCode: HTTP_STATUS.GATEWAY_TIMEOUT,
    data: err.response?.data,
    meta: 'Server did not respond, the data from this error is synthetic',
  });
};

type CallType = {
  url: string;
  method: axios.Method;
  data?: URLParamsType;
  headers?: URLParamsType;
  files?: Object;
  mock?: MockType;
};

const call = ({
  url,
  method,
  data,
  headers,
  // files,
  mock,
}: CallType): Promise<unknown> => {
  if (typeof mock !== 'undefined') {
    const {
      response: { statusCode, body },
    } = mock;
    return delay(mock.delay).then(() => {
      if (fromStatus.isSuccessful(statusCode)) {
        // No body
        if (statusCode === HTTP_STATUS.NO_CONTENT) {
          return Promise.resolve({});
        }

        return Promise.resolve(body);
      }

      throw new HTTPError({
        statusCode,
        data: body,
        meta: 'Server returned a JSON error',
      });
    });
  }

  const request: axios.AxiosRequestConfig = {
    method,
    headers:
      {
        'Content-Type': 'application/json',
        ...headers,
      } || {},
    data: '',
    url,
    // credentials: 'same-origin' as RequestCredentials,
    // mode: 'cors' as RequestMode,
  };

  if (['POST', 'PUT', 'PATCH'].includes(method)) {
    if (typeof data !== 'undefined') {
      request.data = JSON.stringify(data);
    }

    // if(typeof files !== 'undefined') {
    //   const fData = new FormData();
    //   Object.keys(files).forEach(key => {
    //     if(typeof files !== 'undefined') {
    //       fData.append(key, files[key]);

    //     }
    //   });
    //   request.body = fData;
    //   delete request.headers['Content-Type'];
    // }
  }

  return axios.default(request).catch(throwTimeout).then(primaryResponseHandler);
};

export const post = ({ url, data, headers, files, mock }: PostType): FetchPromiseResponseType =>
  call({
    url,
    method: 'POST',
    data,
    headers,
    files,
    mock,
  });

export const put = ({ url, data, headers, files, mock }: PutType): FetchPromiseResponseType =>
  call({
    url,
    method: 'PUT',
    data,
    headers,
    files,
    mock,
  });

export const patch = ({ url, data, headers, files, mock }: PatchType): FetchPromiseResponseType =>
  call({
    url,
    method: 'PATCH',
    data,
    headers,
    files,
    mock,
  });

export const get = ({ url, headers, mock }: GetType): FetchPromiseResponseType =>
  call({
    url,
    method: 'GET',
    headers,
    mock,
  });

export const del = ({ url, headers, mock }: DelType): FetchPromiseResponseType =>
  call({
    url,
    method: 'DELETE',
    headers,
    mock,
  });

export class RESTfulAPI {
  url: string;

  prefix?: string | null;

  dev: boolean;

  constructor(url: string, prefix: string | null, dev: boolean) {
    this.url = url;
    this.prefix = prefix;
    this.dev = dev;
  }

  isInDevMode(): boolean {
    return this.dev;
  }

  getURL(route: string, params: URLParamsType = {}, appendSlash = true): string {
    const base = `${this.url}${this.prefix != null ? `/${this.prefix}/` : '/'}${route}${
      appendSlash ? '/' : ''
    }`;

    if (!isEmptyObject(params)) {
      return `${base}?${toQuery(params)}`;
    }

    return base;
  }
}

type ResourceConstructorType = {
  name: string;
  api: RESTfulAPI;
  headerKey?: string;
  headerPrefix?: string;
  customization?: {
    [key: string]: {
      method: string;
      urlPart: string | Function;
      isDetail: boolean;
      appendSlash?: boolean;
    };
  };
};

export class Resource {
  api: RESTfulAPI;

  name: string;

  custom: { [key: string]: Function };

  getAuthHeaders: (URLParamsType: URLParamsType, arg1?: string) => URLParamsType;

  handleRequest(
    url: string,
    pUrl: string,
    method: string,
    data: URLParamsType,
    headers: URLParamsType,
    files: Object,
    mock?: MockType,
  ): FetchPromiseResponseType {
    const rMock = this.api.isInDevMode() ? mock : undefined;
    switch (method) {
      case 'POST':
        return post({
          url,
          data,
          headers,
          files,
          mock: rMock,
        });
      case 'DELETE':
        return del({
          url: pUrl,
          headers,
          mock: rMock,
        });
      case 'PUT':
        return put({
          url,
          data,
          headers,
          files,
          mock: rMock,
        });
      case 'PATCH':
        return patch({
          url,
          data,
          headers,
          files,
          mock: rMock,
        });
      default:
        return get({
          url: pUrl,
          headers,
          mock: rMock,
        });
    }
  }

  constructor({
    name,
    api,
    headerKey = 'Authorization',
    headerPrefix = 'JWT',
    customization = {},
  }: ResourceConstructorType) {
    this.api = api;
    this.name = name;
    this.custom = {};

    this.getAuthHeaders = (headers, token): URLParamsType =>
      token !== null && typeof token !== 'undefined'
        ? {
            ...headers,
            [headerKey]: `${headerPrefix} ${token}`,
          }
        : headers;

    Object.keys(customization).forEach((key) => {
      const { method, urlPart, isDetail, appendSlash } = customization[key];

      if (isDetail) {
        this.custom[key] = ({
          id,
          filters = {},
          data = {},
          headers = {},
          token,
          files = {},
          urlPartParams = {},
          mock,
        }: CustomDetailMethodType) => {
          let strUrlPart = '';
          if (typeof urlPart === 'string') {
            strUrlPart = urlPart;
          } else if (isFunction(urlPart)) {
            strUrlPart = urlPart(urlPartParams);
          }
          const url = this.api.getURL(`${this.name}/${id}/${strUrlPart}`, {}, appendSlash);
          const pUrl = this.api.getURL(`${this.name}/${id}/${strUrlPart}`, filters, appendSlash);

          return this.handleRequest(
            url,
            pUrl,
            method,
            data,
            this.getAuthHeaders(headers, token),
            files,
            mock,
          );
        };
      } else {
        this.custom[key] = ({
          filters = {},
          data = {},
          headers = {},
          token,
          files = {},
          urlPartParams = {},
          mock,
        }: CustomMethodType) => {
          let strUrlPart = '';
          if (typeof urlPart === 'string') {
            strUrlPart = urlPart;
          } else if (isFunction(urlPart)) {
            strUrlPart = urlPart(urlPartParams);
          }

          const url = this.api.getURL(`${this.name}/${strUrlPart}`, {}, appendSlash);
          const pUrl = this.api.getURL(`${this.name}/${strUrlPart}`, filters, appendSlash);
          return this.handleRequest(
            url,
            pUrl,
            method,
            data,
            this.getAuthHeaders(headers, token),
            files,
            mock,
          );
        };
      }
    });
  }

  list({ filters, headers = {}, token, mock }: ResourceListType): FetchPromiseResponseType {
    return get({
      url: this.api.getURL(this.name, filters),
      headers: this.getAuthHeaders(headers, token),
      mock: this.api.isInDevMode() ? mock : undefined,
    });
  }

  create({
    data,
    headers = {},
    files = {},
    token,
    mock,
  }: CreateResourceType): FetchPromiseResponseType {
    return post({
      url: this.api.getURL(this.name),
      data,
      headers: this.getAuthHeaders(headers, token),
      files,
      mock: this.api.isInDevMode() ? mock : undefined,
    });
  }

  detail({ id, headers = {}, token, mock }: DetailResourceType): FetchPromiseResponseType {
    return get({
      url: this.api.getURL(`${this.name}/${id}`),
      headers: this.getAuthHeaders(headers, token),
      mock: this.api.isInDevMode() ? mock : undefined,
    });
  }

  update({
    id,
    data = {},
    headers = {},
    files = {},
    token,
    mock,
  }: UpdateResourceType): FetchPromiseResponseType {
    return patch({
      url: this.api.getURL(`${this.name}/${id}`),
      data,
      headers: this.getAuthHeaders(headers, token),
      files,
      mock: this.api.isInDevMode() ? mock : undefined,
    });
  }

  replace({
    id,
    data = {},
    headers = {},
    files = {},
    token,
    mock,
  }: ReplaceResourceType): FetchPromiseResponseType {
    return put({
      url: this.api.getURL(`${this.name}/${id}`),
      data,
      headers: this.getAuthHeaders(headers, token),
      files,
      mock: this.api.isInDevMode() ? mock : undefined,
    });
  }

  remove({ id, headers = {}, token, mock }: DetailResourceType): FetchPromiseResponseType {
    return del({
      url: this.api.getURL(`${this.name}/${id}`),
      headers: this.getAuthHeaders(headers, token),
      mock: this.api.isInDevMode() ? mock : undefined,
    });
  }
}
