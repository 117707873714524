import { DJANGO_URLS } from './django-reverse';
import { snakeCase } from 'snake-case';
import { API_BASE_URL } from '../settings';

export type redirectToDjangoUrlType = {
  absUrlName: string;
  args?: djangoUrlArgs;
};

type djangoUrlArgs = {
  [key: string]: string | number;
};

type urlGenerator = (args?: djangoUrlArgs) => string;

type urlConfigType = {
  [key: string]: {
    template: string;
    args: string[];
  };
};

export const djangoUrls = (absUrlName: string): urlGenerator => {
  const UrlConfig: urlConfigType = {};

  // parse url configurations from json list
  DJANGO_URLS.urls.forEach(([name, [[template, args]]]) => {
    if (name === absUrlName) {
      UrlConfig[absUrlName] = {
        template,
        args,
      };
    }
  });

  if (!(absUrlName in UrlConfig)) throw `${absUrlName} is not declared in Django Urls`;

  return (urlArgs?: djangoUrlArgs): string => {
    const { template, args } = UrlConfig[absUrlName];
    let url = template;

    // validate url args
    if (urlArgs !== undefined) {
      Object.keys(urlArgs).forEach((key) => {
        if (args.findIndex((arg) => arg === snakeCase(key)) === -1) {
          throw `'${key}' -> '${snakeCase(key)}' is a invalid argument`;
        }

        // interpolate values
        url = url.replace(`%(${snakeCase(key)})s`, String(urlArgs[key]));
      });
    }

    return `${API_BASE_URL}/${url}`;
  };
};
