import React from 'react';
import Portal from '../../components/Portal';
import { ConfigUpdateInfo } from '../SchoolConfig';

const ConfigUpdateTutorInfo: React.FunctionComponent = () => {
  return (
    <Portal selector={'#config-update-tutor-info'}>
      <ConfigUpdateInfo/>
    </Portal>
  );
}

export default ConfigUpdateTutorInfo;