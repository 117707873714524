import { snakeCase } from 'lodash';

export const arrayReplace = <T>(list: Array<T>, element: T, newElement: T): Array<T> =>
  list.map((e) => {
    if (e === element) {
      return newElement;
    }
    return e;
  });

export const onEnter = (fn: Function, nfn: Function = (_: any) => _): Function => (e: any) => {
  if (e.key === 'Enter') {
    return fn(e);
  }

  return nfn(e);
};

export const getFileExtension = (file: string) => {
  const splitted = file.split('.');

  return splitted[splitted.length - 1];
};

export const getFileName = (file: string) => {
  const splitted = file.split('.');

  return splitted.length <= 1 ? file : splitted.slice(0, splitted.length - 1).join('.');
};

export const normalize = (data: any[], id: any = 'id'): Record<string, any> => {
  const ret: any = {};

  data.forEach((item: any) => {
    if (item[id]) {
      ret[item[id]] = item;
    }
  });

  return ret;
};

export const capitalizeFirstLetter = ([first, ...rest]: any, locale = navigator.language) =>
  [first.toLocaleUpperCase(locale), ...rest].join('');

export const isEmptyObject = (obj: Record<string, any>) => {
  if (!obj || typeof obj !== 'object' || Array.isArray(obj)) return false;
  return !Object.keys(obj).length;
};

export const toSnakeCase = (obj: any) => {
  const snakeCaseObj:any = {};
  for (const key of Object.keys(obj)){
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      snakeCaseObj[snakeCase(key)] = obj[key];
    }
  }

  return snakeCaseObj;
};

export const parseBoolToFormBooleans = (obj: any) => {
  const formBoolObj:any = {};
  for (const key of Object.keys(obj)){
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'boolean') {
        formBoolObj[key] = obj[key] && obj[key] === true ? ['on'] : undefined;
      } else {
        formBoolObj[key] = obj[key];
      }
    }
  }

  return formBoolObj;
}

export const shoutSuccess = (text: string) => {
  // This is a simple fix for avoid window props due to Typescript compile errors...
  const myWindow:any = window;
  myWindow["shoutSuccess"](text);
  return;
}

export const shoutError = (text: string) => {
  // This is a simple fix for avoid window props due to Typescript compile errors...
  const myWindow:any = window;
  myWindow["shoutError"](text);
  return;
}